// @flow

import * as React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

export const ApplicationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: max-content;
`

export const ApplicationHeader = styled.header`
  min-width: max-content;
`

const BodyBackgroundColor = createGlobalStyle`
  html, body {
    background-color: #f9fafc;
  }
`
const MainView = styled.div`
  flex-grow: 1;
  position: relative;
`

export const ApplicationMainView = ({ children }: { children: React.Node }) => (
  <React.Fragment>
    <BodyBackgroundColor />
    <MainView>{children}</MainView>
  </React.Fragment>
)
