// @flow

import * as React from 'react'
import styled from 'styled-components'
import {
  KanbanWrapper,
  KanbanLayout,
  DragDropKanban,
  DroppableColumn,
  DraggableCard,
  SelectRadio,
  RobotoMedium,
  Colors,
  Link,
  DialogModal,
  FinancialInformations,
  CustomerSelection,
  CustomerInformationsForm,
  Tabs,
  Tab,
  SecondaryButton,
  PriceInformations,
} from './../../Guidelines'
import ApplicationLayout from '../../ApplicationLayout'
import { inject, observer } from 'mobx-react'
import LotsStore from '../../Stores/LotsStore'
import { type LotStatus } from '../../Domain/Lot'
import { type LotVat, type LotLoan } from '../../Domain/CommercialOffer'
import ProgramsStore from '../../Stores/ProgramsStore'
import routes from '../../Routes'
import RouterStore from '../../Stores/RouterStore'
import AuthenticationStore from '../../Stores/AuthenticationStore'

const FiltersContainer = styled.div`
  flex: none;
  display: flex;
  align-items: center;
`

const LabelFilters = styled.div`
  text-shadow: 0 1px 0 ${Colors.white};
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  text-transform: uppercase;
  color: ${Colors.blackUltraBold};
  margin-right: 8px;
`

const ResetFilters = styled.div`
  color: ${Colors.green};
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  text-shadow: 0 1px 0 white;

  &:hover {
    text-decoration: underline;
  }
`

const SubNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 30px 0 30px;
`

export default inject(
  'lotsStore',
  'programsStore',
  'routerStore',
  'authenticationStore',
)(
  observer(
    ({
      lotsStore,
      programsStore,
      routerStore,
      authenticationStore,
    }: {
      lotsStore: LotsStore,
      programsStore: ProgramsStore,
      routerStore: RouterStore,
      authenticationStore: AuthenticationStore,
    }) => {
      const onDrop = (
        lotId: string,
        columnStatus: LotStatus,
        lotStatus: LotStatus,
      ) => {
        if (
          (columnStatus === 'optioned' || columnStatus === 'booked') &&
          lotStatus === 'available'
        ) {
          lotsStore.openModalAssociation(lotId, columnStatus)
        } else {
          lotsStore.updateLotStatus(lotId, columnStatus)
        }
      }
      const program = lotsStore.programId
        ? programsStore.getProgram(lotsStore.programId)
        : null

      const onClickCard = (lotId: string) => {
        lotsStore.openCommercialOffertEditionModal(lotId)
      }

      const onEditFinancialInformations = (
        priceIncludingTaxes: number,
        vat: LotVat,
        loan: LotLoan,
        status: LotStatus,
      ) => {
        lotsStore.editFinancialInformations(
          priceIncludingTaxes,
          vat,
          loan,
          status,
        )
      }

      const onSubmitLotAssociation = (
        priceIncludingTaxes: number,
        vat: LotVat,
        loan: LotLoan,
        status: LotStatus,
      ) => {
        lotsStore.updateLotAssociation(priceIncludingTaxes, vat, loan, status)
      }

      const onCustomerSelected = (customerId: string) => {
        lotsStore.onCustomerSelected(customerId)
      }

      const openNewTabReceipt = (receiptUrl: ?string) => {
        if (!authenticationStore.token) {
          throw new Error('User access token is missing')
        }
        if (!receiptUrl) {
          throw new Error('Receipt url is missing')
        }

        window.open(
          `${receiptUrl}?access_token=${authenticationStore.token.accessToken}`,
          '_blank',
        )
      }

      const onEditCustomerInformations = ({
        emailValue,
        firstNameValue,
        lastNameValue,
        phoneValue,
        addressValue,
        zipCodeValue,
        cityValue,
      }: {
        emailValue: string,
        firstNameValue: string,
        lastNameValue: string,
        phoneValue: string,
        addressValue: string,
        zipCodeValue: string,
        cityValue: string,
      }) => {
        lotsStore.editCustomerInformations({
          emailValue,
          firstNameValue,
          lastNameValue,
          phoneValue,
          addressValue,
          zipCodeValue,
          cityValue,
        })
      }

      const onCustomerValidate = ({
        emailValue,
        firstNameValue,
        lastNameValue,
        phoneValue,
        addressValue,
        zipCodeValue,
        cityValue,
      }: {
        emailValue: string,
        firstNameValue: string,
        lastNameValue: string,
        phoneValue: string,
        addressValue: string,
        zipCodeValue: string,
        cityValue: string,
      }) => {
        lotsStore.onCustomerValidate({
          emailValue,
          firstNameValue,
          lastNameValue,
          phoneValue,
          addressValue,
          zipCodeValue,
          cityValue,
        })
      }

      if (!program) {
        return <ApplicationLayout breadcrumb={[]} />
      }

      const isBusinessPack = program.isBusinessPack()

      const lotCommercialOfferEdition = lotsStore.lotCommercialOfferEdition

      return (
        <ApplicationLayout
          breadcrumb={[
            <Link
              key={`${program.id}-lots`}
              view={routes.lots_management_page}
              params={{ programId: program.id }}
              router={routerStore}
            >
              {program.label}
            </Link>,
          ]}
          breadcrumbCallToAction={
            <SecondaryButton
              value="Voir l’opération"
              onClick={() => window.open(program.programPreviewUrl)}
            />
          }
        >
          <KanbanWrapper>
            <KanbanLayout>
              <SubNavigation>
                <FiltersContainer>
                  <LabelFilters>Filtres</LabelFilters>

                  {lotsStore.filters.map(oneFilter => {
                    return (
                      <SelectRadio
                        key={oneFilter.id}
                        id={oneFilter.id}
                        label={oneFilter.label}
                        comparator={oneFilter.valueComparator}
                        choices={oneFilter.choices.map(oneChoice => ({
                          label: oneChoice.label,
                          value: oneChoice.value,
                        }))}
                        onChoiceClick={(id, value) =>
                          lotsStore.updateFilters(id, value)
                        }
                        values={oneFilter.values}
                      />
                    )
                  })}

                  {lotsStore.atLeastOneFilterIsActive ? (
                    <ResetFilters onClick={() => lotsStore.resetFilterSet()}>
                      Réinitialiser les filtres
                    </ResetFilters>
                  ) : null}
                </FiltersContainer>

                <SecondaryButton
                  value="Paramètres"
                  onClick={() =>
                    routerStore.goTo(routes.lots_prices_bulk_edition_page, {
                      programId: program.id,
                    })
                  }
                />
              </SubNavigation>

              <DragDropKanban>
                {lotsStore.columns.map((column, index) => {
                  const lotsForCurrentStatus =
                    lotsStore.lotsByStatus[column.status] || []

                  return (
                    <DroppableColumn
                      key={column.status}
                      columnStatus={column.status}
                      title={`${column.title}\u00A0(${lotsForCurrentStatus.length})`}
                      collapsable={column.collapsable}
                      onDrop={onDrop}
                      onSwitch={lotsStore.switchVat}
                      displayedVat={lotsStore.displayedVat}
                    >
                      {lotsForCurrentStatus.map(lot => {
                        const vat = lot.commercialOffer
                          ? lot.commercialOffer.vat
                          : lotsStore.displayedVat
                        return (
                          <DraggableCard
                            key={lot.lotId}
                            toColumn={column.to}
                            lotId={lot.lotId}
                            status={lot.status}
                            label={lot.label}
                            vat={vat}
                            typology={lot.typology}
                            area={lot.area}
                            balconyArea={lot.balconyArea}
                            gardenArea={lot.gardenArea}
                            orientation={lot.orientation}
                            floor={lot.floor}
                            onClickCard={
                              lot.haveEditableCommercialOffert
                                ? onClickCard
                                : null
                            }
                            price={
                              vat === 20
                                ? lot.visiblePriceWithVat20
                                : lot.visiblePriceWithVat5
                            }
                            loanLabel={
                              lot.commercialOffer
                                ? lot.commercialOffer.loanLabel
                                : undefined
                            }
                            vatIsFixed={!!lot.commercialOffer}
                            lotStatus={lot.status}
                            faded={lot.faded}
                            customerNames={lot.commercialOfferCustomerName}
                          />
                        )
                      })}
                    </DroppableColumn>
                  )
                })}
              </DragDropKanban>
            </KanbanLayout>
          </KanbanWrapper>

          {lotCommercialOfferEdition ? (
            <DialogModal
              title={lotCommercialOfferEdition.lot.label}
              onClose={lotsStore.closeEditModal}
              linkTo={lotCommercialOfferEdition.lot.previewUrl}
              subTitle="Informations de vente"
            >
              <Tabs>
                <Tab id="financial" title="Finance">
                  <FinancialInformations
                    programVat={program.vat}
                    commercialOfferPrice={
                      lotCommercialOfferEdition.lot
                        .haveCommercialOffertAssociatied
                        ? lotCommercialOfferEdition.lot.commercialOfferPrice
                        : null
                    }
                    commercialOfferVat={
                      lotCommercialOfferEdition.lot
                        .haveCommercialOffertAssociatied
                        ? lotCommercialOfferEdition.lot.commercialOfferVat
                        : null
                    }
                    commercialOfferLoan={
                      lotCommercialOfferEdition.lot
                        .haveCommercialOffertAssociatied
                        ? lotCommercialOfferEdition.lot.commercialOfferLoan
                        : null
                    }
                    labelButton="Enregistrer"
                    status={lotCommercialOfferEdition.lot.status}
                    onSubmit={onEditFinancialInformations}
                    priceIncludingTaxes55={
                      lotCommercialOfferEdition.lot.priceIncludingTaxes55
                    }
                    priceIncludingTaxes20={
                      lotCommercialOfferEdition.lot.priceIncludingTaxes20
                    }
                  />
                </Tab>
                {lotCommercialOfferEdition.customer && (
                  <Tab id="customer" title="Client">
                    <CustomerInformationsForm
                      email={
                        lotCommercialOfferEdition.customer
                          ? lotCommercialOfferEdition.customer.email
                          : ''
                      }
                      firstName={
                        lotCommercialOfferEdition.customer
                          ? lotCommercialOfferEdition.customer.firstName
                          : ''
                      }
                      lastName={
                        lotCommercialOfferEdition.customer
                          ? lotCommercialOfferEdition.customer.lastName
                          : ''
                      }
                      phone={
                        lotCommercialOfferEdition.customer
                          ? lotCommercialOfferEdition.customer.phone
                          : ''
                      }
                      address={
                        lotCommercialOfferEdition.customer
                          ? lotCommercialOfferEdition.customer.address
                          : ''
                      }
                      zipCode={
                        lotCommercialOfferEdition.customer
                          ? lotCommercialOfferEdition.customer.zipCode
                          : ''
                      }
                      city={
                        lotCommercialOfferEdition.customer
                          ? lotCommercialOfferEdition.customer.city
                          : ''
                      }
                      customerId={
                        lotCommercialOfferEdition.customer
                          ? lotCommercialOfferEdition.customer.customerId
                          : ''
                      }
                      customers={[]}
                      fetchLoadedCustomer={null}
                      errors={lotCommercialOfferEdition.errors}
                      labelButton="Enregistrer"
                      onSubmit={onEditCustomerInformations}
                    />
                  </Tab>
                )}
                {isBusinessPack ? (
                  <Tab id="price" title="Prix">
                    <PriceInformations
                      lotPriceIncludingVAT={
                        lotCommercialOfferEdition.lot.commercialOffer &&
                        lotCommercialOfferEdition.lot.commercialOffer
                          .lotPriceIncludingVAT
                      }
                      optionsPriceIncludingVAT={
                        lotCommercialOfferEdition.lot.commercialOffer &&
                        lotCommercialOfferEdition.lot.commercialOffer
                          .configurationOptionPrice
                      }
                      totalPriceIncludingVAT={
                        lotCommercialOfferEdition.lot.commercialOffer &&
                        lotCommercialOfferEdition.lot.commercialOffer
                          .totalPriceIncludingVAT
                      }
                      receiptUrl={
                        lotCommercialOfferEdition.lot.commercialOffer &&
                        lotCommercialOfferEdition.lot.commercialOffer.receiptUrl
                      }
                      openNewTabReceipt={() =>
                        openNewTabReceipt(
                          lotCommercialOfferEdition.lot.commercialOffer &&
                            lotCommercialOfferEdition.lot.commercialOffer
                              .receiptUrl,
                        )
                      }
                    />
                  </Tab>
                ) : null}
              </Tabs>
            </DialogModal>
          ) : null}

          {lotsStore.lotAssociation ? (
            <DialogModal
              title={lotsStore.lotAssociation.lot.label}
              onClose={lotsStore.closeAssociationModal}
              linkTo={lotsStore.lotAssociation.lot.previewUrl}
              subTitle={
                lotsStore.lotAssociation.step === 'customer_selection'
                  ? 'Association du client'
                  : lotsStore.lotAssociation.step === 'customer_informations'
                  ? 'Informations du client'
                  : 'Informations de vente'
              }
              steps={[
                'customer_selection',
                'customer_informations',
                'financial_informations',
              ]}
              stepActive={lotsStore.lotAssociation.step}
            >
              {lotsStore.lotAssociation.step === 'customer_selection' ? (
                <CustomerSelection
                  onNewCustomer={lotsStore.onNewCustomer}
                  customers={[...lotsStore.lotAssociation.customerSummaries]}
                  onCustomerSelected={onCustomerSelected}
                  onSkipCustomer={lotsStore.onSkipCustomer}
                />
              ) : lotsStore.lotAssociation.step === 'customer_informations' ? (
                <CustomerInformationsForm
                  customers={[...lotsStore.lotAssociation.customerSummaries]}
                  errors={lotsStore.lotAssociation.errors}
                  email={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.email || ''
                      : ''
                  }
                  firstName={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.firstName || ''
                      : ''
                  }
                  lastName={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.lastName || ''
                      : ''
                  }
                  phone={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.phone || ''
                      : ''
                  }
                  address={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.address || ''
                      : ''
                  }
                  zipCode={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.zipCode || ''
                      : ''
                  }
                  city={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.city || ''
                      : ''
                  }
                  customerId={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.customerId
                      : null
                  }
                  fetchLoadedCustomer={onCustomerSelected}
                  labelButton="Valider"
                  onSubmit={onCustomerValidate}
                />
              ) : (
                <FinancialInformations
                  programVat={program.vat}
                  commercialOfferPrice={
                    lotsStore.lotAssociation.lot.haveCommercialOffertAssociatied
                      ? lotsStore.lotAssociation.lot.commercialOfferPrice
                      : null
                  }
                  commercialOfferVat={
                    lotsStore.lotAssociation.lot.haveCommercialOffertAssociatied
                      ? lotsStore.lotAssociation.lot.commercialOfferVat
                      : null
                  }
                  commercialOfferLoan={
                    lotsStore.lotAssociation.lot.haveCommercialOffertAssociatied
                      ? lotsStore.lotAssociation.lot.commercialOfferLoan
                      : null
                  }
                  labelButton={
                    lotsStore.lotAssociation.nextStatus === 'optioned'
                      ? 'Optionner'
                      : 'Réserver'
                  }
                  status={lotsStore.lotAssociation.nextStatus}
                  onSubmit={onSubmitLotAssociation}
                  priceIncludingTaxes55={
                    lotsStore.lotAssociation.lot.priceIncludingTaxes55
                  }
                  priceIncludingTaxes20={
                    lotsStore.lotAssociation.lot.priceIncludingTaxes20
                  }
                />
              )}
            </DialogModal>
          ) : null}
        </ApplicationLayout>
      )
    },
  ),
)
