// @flow

export const floorLabel = (floor: number) => {
  if (floor === 0) {
    return 'RDC'
  }

  if (floor < 0) {
    return `R-${floor}`
  }

  return `R+${floor}`
}
