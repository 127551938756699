// @flow

import { action, observable, runInAction, computed } from 'mobx'
import { RootStore } from '../RootStore'
import Lot, { type LotStatus } from '../Domain/Lot'
import FilterSet, { type FilterDescription } from '../Domain/Filter/FilterSet'
import { lotFilterSetBuilder } from '../Domain/lotFilterSetBuilder'
import CommercialOffer, {
  type LotVat,
  type LotLoan,
} from '../Domain/CommercialOffer'
import CustomerSummary from '../Domain/CustomerSummary'
import Configuration from '../Domain/Configuration'

type Column = {|
  status: string,
  title: string,
  to: string[],
  sortBy: 'label' | 'status_changed',
  collapsable: boolean,
|}

type CustomerSummariesPayload = {
  customerId: string,
  firstName: string,
  lastName: ?string,
  email: string,
}

type CustomerInformations = {
  customerId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  zipCode: string,
  address: string,
  city: string,
}

const COLUMNS = [
  {
    status: 'unavailable',
    title: 'Indisponibles',
    to: ['available'],
    sortBy: 'label',
    collapsable: true,
  },
  {
    status: 'available',
    title: 'Disponibles',
    to: ['unavailable', 'optioned', 'booked'],
    sortBy: 'label',
    collapsable: false,
  },
  {
    status: 'optioned',
    title: 'Optionnés',
    to: ['booked', 'available'],
    sortBy: 'status_changed',
    collapsable: false,
  },
  {
    status: 'booked',
    title: 'Réservés',
    to: ['sold', 'available'],
    sortBy: 'status_changed',
    collapsable: false,
  },
  {
    status: 'sold',
    title: 'Vendus',
    to: ['available', 'booked'],
    sortBy: 'status_changed',
    collapsable: false,
  },
]

type LotAssociationStep =
  | 'customer_selection'
  | 'customer_informations'
  | 'financial_informations'

export default class LotsStore {
  +rootStore: RootStore
  +columns: Column[]

  @observable programId: ?string = null
  @observable lots: Lot[] = []
  @observable filterSet: FilterSet<Lot> = FilterSet.fromFilterDefinitions([])
  @observable displayedVat: 5.5 | 20 = 20
  @observable lotCommercialOfferEdition: ?{
    lot: Lot,
    customer: ?CustomerInformations,
    errors: { source: string, message: string }[],
  } = null

  @observable lotAssociation: ?{
    lot: Lot,
    nextStatus: LotStatus,
    step: LotAssociationStep,
    customer: ?CustomerInformations,
    customerSummaries: CustomerSummariesPayload[],
    errors: { source: string, message: string }[],
  } = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.columns = COLUMNS
  }

  @action async fetchLotsByProgram(programId: string) {
    const program = this.rootStore.programsStore.getProgram(programId)
    if (!program) {
      throw new Error(`No program found with the id "${programId}"`)
    }

    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}/lots`,
    )

    runInAction(() => {
      this.programId = programId

      this.displayedVat = program.vat

      this.lots = response.data.map(
        oneLotPayload =>
          new Lot(
            oneLotPayload.lotId,
            oneLotPayload.status,
            new Date(oneLotPayload.statusChangedAt),
            oneLotPayload.label,
            oneLotPayload.priceIncludingTaxes20,
            oneLotPayload.priceIncludingTaxes55,
            oneLotPayload.orientation,
            oneLotPayload.area,
            oneLotPayload.option ? oneLotPayload.option.balcony : null,
            oneLotPayload.option ? oneLotPayload.option.garden : null,
            oneLotPayload.typology,
            oneLotPayload.floorNumber,
            oneLotPayload.buildingId,
            oneLotPayload.plan,
            oneLotPayload.commercialOffer
              ? new CommercialOffer(
                  oneLotPayload.commercialOffer.vat,
                  oneLotPayload.commercialOffer.loan,
                  oneLotPayload.commercialOffer.customer
                    ? new CustomerSummary(
                        oneLotPayload.commercialOffer.customer.customerId,
                        oneLotPayload.commercialOffer.customer.firstName,
                        oneLotPayload.commercialOffer.customer.lastName,
                      )
                    : null,
                  oneLotPayload.commercialOffer.lotPriceIncludingVAT,
                  oneLotPayload.commercialOffer.configuration
                    ? new Configuration(
                        oneLotPayload.commercialOffer.configuration.configurationId,
                        oneLotPayload.commercialOffer.configuration.configurationOptionPrice,
                        oneLotPayload.commercialOffer.configuration.receiptUrl,
                      )
                    : null,
                  oneLotPayload.commercialOffer.totalPriceIncludingVAT,
                )
              : null,
            program,
            oneLotPayload.duplex,
          ),
      )

      this.resetFilterSet()
    })
  }

  @action async updateLotStatus(lotId: string, status: LotStatus) {
    const lot = this.findLotById(lotId)

    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${this.programId}/lot/${lotId}/status`,
      {
        status: status,
        commercialOffer: lot.commercialOfferUpdateApi,
      },
    )

    runInAction(() => {
      lot.updateStatus(status)
    })
  }

  @action async updateLotAssociation(
    priceIncludingTaxes: number,
    vat: LotVat,
    loan: LotLoan,
    status: LotStatus,
  ) {
    const lotAssociation = this.lotAssociation
    if (!lotAssociation) {
      throw new Error('No lot current associating')
    }
    const lotCustomer = lotAssociation.customer

    const lot = this.findLotById(lotAssociation.lot.lotId)

    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${this.programId}/lot/${lot.lotId}/status`,
      {
        status: status,
        commercialOffer: {
          priceIncludingTaxes: priceIncludingTaxes,
          vat: vat,
          loan: loan,
          customerId: lotCustomer ? lotCustomer.customerId : null,
        },
      },
    )

    this.pingConfiguration(lot)
    this.closeAssociationModal()

    runInAction(() => {
      lot.updateStatus(status)
      lot.createCommercialOffer(
        priceIncludingTaxes,
        vat,
        loan,
        lotCustomer ? lotCustomer.customerId : null,
        lotCustomer ? lotCustomer.firstName : null,
        lotCustomer ? lotCustomer.lastName : null,
      )
    })
  }

  @action async pingConfiguration(lot: Lot) {
    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    const updatedLot = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${this.programId}/lots/${lot.lotId}`,
    )

    const configurationId =
      updatedLot.data.commercialOffer &&
      updatedLot.data.commercialOffer.configuration &&
      updatedLot.data.commercialOffer.configuration.configurationId
    const configurationOptionPrice =
      updatedLot.data.commercialOffer &&
      updatedLot.data.commercialOffer.configuration &&
      updatedLot.data.commercialOffer.configuration.configurationOptionPrice
    const receiptUrl =
      updatedLot.data.commercialOffer &&
      updatedLot.data.commercialOffer.configuration &&
      updatedLot.data.commercialOffer.configuration.receiptUrl

    if (
      !configurationId ||
      typeof configurationOptionPrice !== 'number' ||
      !receiptUrl
    ) {
      setTimeout(() => {
        this.pingConfiguration(lot)
      }, 5000)
    } else {
      runInAction(() => {
        lot.updateConfiguration(
          configurationId,
          configurationOptionPrice,
          receiptUrl,
        )
      })
    }
  }

  @action async editFinancialInformations(
    priceIncludingTaxes: number,
    vat: number,
    loan: LotLoan,
    status: LotStatus,
  ) {
    if (!this.lotCommercialOfferEdition) {
      throw new Error('No lot current editing')
    }

    const lotCustomer = this.lotCommercialOfferEdition.customer
    if (!lotCustomer) {
      throw new Error('No customer current editing')
    }

    const lot = this.findLotById(this.lotCommercialOfferEdition.lot.lotId)

    const programId = this.programId
    if (!programId) {
      throw new Error('No program id linked to this store')
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${programId}/lot/${lot.lotId}/status`,
      {
        status: status,
        commercialOffer: {
          priceIncludingTaxes: priceIncludingTaxes,
          vat: vat,
          loan: loan,
          customerId: lotCustomer.customerId,
        },
      },
    )

    const updatedLot = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}/lots/${lot.lotId}`,
    )

    this.closeEditModal()

    runInAction(() => {
      lot.updateFinancialInformations(
        updatedLot.data.commercialOffer.vat,
        updatedLot.data.commercialOffer.loan,
        updatedLot.data.commercialOffer.lotPriceIncludingVAT,
        updatedLot.data.commercialOffer.totalPriceIncludingVAT,
      )
    })
  }

  @action resetFilterSet() {
    const programId = this.programId
    if (!programId) {
      throw new Error('No program id linked to this store')
    }

    const program = this.rootStore.programsStore.getProgram(programId)

    this.filterSet = lotFilterSetBuilder(this.lots, program)
  }

  @action updateFilters(id: string, value: any) {
    this.filterSet = this.filterSet.toogleFilterValue(id, value)
  }

  @computed get filters(): FilterDescription[] {
    return this.filterSet.describe()
  }

  @computed get atLeastOneFilterIsActive() {
    return this.filterSet.atLeastOneFilterIsActive()
  }

  @computed get visibleLots(): Lot[] {
    return this.filterSet.filter(this.lots)
  }

  @computed get lotsByStatus(): { [keys: string]: Lot[] } {
    const lotsByStatus = {}

    this.columns.forEach(({ status, sortBy }) => {
      lotsByStatus[status] = this.visibleLots
        .filter(oneLot => oneLot.status === status)
        .sort(
          sortBy === 'label'
            ? this._sortLotsByLabel
            : this._sortLotsByStatusChanged,
        )
    })

    return lotsByStatus
  }

  findLotById = (lotId: string): Lot => {
    const lot = this.lots.find(lot => lot.is(lotId))
    if (!lot) {
      throw new Error(`Lot "${lotId}" not found`)
    }

    return lot
  }

  @action async openCommercialOffertEditionModal(lotId: string) {
    const lot = this.findLotById(lotId)
    if (!lot.haveEditableCommercialOffert) {
      throw new Error(
        `The lot ${lot.label} is not editable. Id: "${lot.lotId}"`,
      )
    }

    this.lotCommercialOfferEdition = {
      lot: lot,
      errors: [],
      customer: null,
    }

    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    if (!lot.commercialOffer) {
      throw new Error('The not does not have a commercial offert')
    }

    const customerResponse =
      lot.commercialOffer.customer &&
      (await this.rootStore.authenticationStore.httpClient.get(
        `/api/seller/program/${this.programId}/customers/${lot.commercialOffer.customer.customerId}`,
      ))

    const customer = customerResponse && customerResponse.data
    runInAction(() => {
      if (this.lotCommercialOfferEdition) {
        this.lotCommercialOfferEdition = {
          lot: lot,
          errors: [],
          customer: customer
            ? {
                customerId: customer.customerId,
                firstName: customer.firstName,
                lastName: customer.lastName,
                email: customer.email,
                phone: customer.phone,
                zipCode: customer.zipCode,
                address: customer.street,
                city: customer.city,
              }
            : null,
        }
      }
    })
  }

  @action async openModalAssociation(lotId: string, nextStatus: LotStatus) {
    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    const customersResponse = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${this.programId}/customers/summaries`,
    )

    runInAction(() => {
      this.lotAssociation = {
        lot: this.findLotById(lotId),
        nextStatus: nextStatus,
        step: 'customer_selection',
        customer: null,
        customerSummaries: customersResponse.data,
        errors: [],
      }
    })
  }

  @action onSkipCustomer = () => {
    if (!this.lotAssociation) {
      throw new Error('No lot current associating')
    }
    this.lotAssociation.step = 'financial_informations'
  }

  @action onNewCustomer = () => {
    if (!this.lotAssociation) {
      throw new Error('No lot current associating')
    }
    this.lotAssociation.step = 'customer_informations'
  }

  @action async onCustomerSelected(customerId: string) {
    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    const lotAssociation = this.lotAssociation
    if (!lotAssociation) {
      throw new Error('No lot current associating')
    }

    lotAssociation.step = 'customer_informations'

    const customerResponse = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${this.programId}/customers/${customerId}`,
    )

    const customer = customerResponse.data
    runInAction(() => {
      lotAssociation.customer = {
        customerId: customer.customerId,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        zipCode: customer.zipCode,
        address: customer.street,
        city: customer.city,
      }
    })
  }

  @action async editCustomerInformations({
    emailValue,
    firstNameValue,
    lastNameValue,
    phoneValue,
    addressValue,
    zipCodeValue,
    cityValue,
  }: {
    emailValue: string,
    firstNameValue: string,
    lastNameValue: string,
    phoneValue: string,
    addressValue: string,
    zipCodeValue: string,
    cityValue: string,
  }) {
    const lotCommercialOfferEdition = this.lotCommercialOfferEdition
    if (!lotCommercialOfferEdition) {
      throw new Error('No lot current editing')
    }

    const lotCustomer = lotCommercialOfferEdition.customer
    if (!lotCustomer) {
      throw new Error('No customer current editing')
    }

    const lot = this.findLotById(lotCommercialOfferEdition.lot.lotId)

    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    try {
      await this.rootStore.authenticationStore.httpClient.put(
        `/api/seller/program/${this.programId}/customer/${lotCustomer.customerId}`,
        {
          firstName: firstNameValue,
          lastName: lastNameValue,
          email: emailValue,
          phone: phoneValue,
          street: addressValue,
          zipCode: zipCodeValue,
          city: cityValue,
        },
      )

      this.closeEditModal()

      runInAction(() => {
        lot.updateCustomer(firstNameValue, lastNameValue)
      })
    } catch (err) {
      runInAction(() => {
        lotCommercialOfferEdition.errors = err.response.data
      })
    }
  }

  @action async onCustomerValidate({
    emailValue,
    firstNameValue,
    lastNameValue,
    phoneValue,
    addressValue,
    zipCodeValue,
    cityValue,
  }: {
    emailValue: string,
    firstNameValue: string,
    lastNameValue: string,
    phoneValue: string,
    addressValue: string,
    zipCodeValue: string,
    cityValue: string,
  }) {
    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }
    const lotAssociation = this.lotAssociation
    if (!lotAssociation) {
      throw new Error('No lot current associating')
    }

    if (lotAssociation.customer && lotAssociation.customer.customerId) {
      const customerId = lotAssociation.customer.customerId
      try {
        await this.rootStore.authenticationStore.httpClient.put(
          `/api/seller/program/${this.programId}/customer/${customerId}`,
          {
            firstName: firstNameValue,
            lastName: lastNameValue,
            email: emailValue,
            phone: phoneValue,
            street: addressValue,
            zipCode: zipCodeValue,
            city: cityValue,
          },
        )

        runInAction(() => {
          lotAssociation.step = 'financial_informations'
          lotAssociation.customer = {
            customerId: customerId,
            firstName: firstNameValue,
            lastName: lastNameValue,
            email: emailValue,
            phone: phoneValue,
            address: addressValue,
            zipCode: zipCodeValue,
            city: cityValue,
          }
        })
      } catch (err) {
        runInAction(() => {
          lotAssociation.errors = err.response.data
        })
      }
    } else {
      try {
        const response = await this.rootStore.authenticationStore.httpClient.post(
          `/api/seller/program/${this.programId}/customer`,
          {
            firstName: firstNameValue,
            lastName: lastNameValue,
            email: emailValue,
            phone: phoneValue,
            street: addressValue,
            zipCode: zipCodeValue,
            city: cityValue,
          },
        )

        runInAction(() => {
          lotAssociation.customer = {
            customerId: response.data.customerId,
            firstName: firstNameValue,
            lastName: lastNameValue,
            email: emailValue,
            phone: phoneValue,
            address: addressValue,
            zipCode: zipCodeValue,
            city: cityValue,
          }
          lotAssociation.step = 'financial_informations'
        })
      } catch (err) {
        runInAction(() => {
          lotAssociation.errors = err.response.data
        })
      }
    }
  }

  @action closeAssociationModal = () => {
    this.lotAssociation = null
  }

  @action closeEditModal = () => {
    this.lotCommercialOfferEdition = null
  }

  @action async editPrice(priceIncludingTaxes20: number) {
    if (!this.lotCommercialOfferEdition) {
      throw new Error('No lot current editing')
    }

    const lot = this.findLotById(this.lotCommercialOfferEdition.lot.lotId)

    const programId = this.programId
    if (!programId) {
      throw new Error('No program id linked to this store')
    }

    try {
      await this.rootStore.authenticationStore.httpClient.put(
        `/api/seller/program/${programId}/lot/${lot.lotId}/price`,
        {
          priceIncludingTaxes20,
        },
      )

      const updatedLot = await this.rootStore.authenticationStore.httpClient.get(
        `/api/seller/program/${programId}/lots/${lot.lotId}`,
      )

      this.closeEditModal()

      runInAction(() => {
        lot.updatePrice(
          updatedLot.data.priceIncludingTaxes20,
          updatedLot.data.priceIncludingTaxes55,
        )
      })
    } catch (err) {
      runInAction(() => {
        if (this.lotCommercialOfferEdition) {
          this.lotCommercialOfferEdition.errors = err.response.data
        }
      })
    }
  }

  @action switchVat = () => {
    this.displayedVat = this.displayedVat === 20 ? 5.5 : 20
  }

  _sortLotsByLabel(lotA: Lot, lotB: Lot): -1 | 0 | 1 {
    return lotA.label < lotB.label ? -1 : 1
  }

  _sortLotsByStatusChanged(lotA: Lot, lotB: Lot): -1 | 0 | 1 {
    return lotA.statusChangedAt > lotB.statusChangedAt ? -1 : 1
  }
}
