// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#ADB1C2"
      fillRule="nonzero"
      d="M15.3 15.999c.2 0 .35-.084.5-.234.217-.216.25-.433.134-.733-.633-1.85-1.216-3.717-1.833-5.617a.644.644 0 0 0-.2-.317L5.087.232a.806.806 0 0 0-.983-.117c-.833.517-1.6 1.083-2.266 1.75A10.823 10.823 0 0 0 .122 3.998c-.2.317-.15.734.117.984l8.814 8.866c.1.084.2.15.316.2 1.9.617 3.816 1.25 5.665 1.9a.69.69 0 0 0 .267.05zm-3.115-2.65c-.733-.25-1.566-.5-2.283-.734l-8.23-8.267c.216-.316.466-.65.75-.95l7.997 8.017a.762.762 0 0 0 .566.217c.2 0 .35-.084.5-.234.317-.316.333-.75.034-1.066L3.554 2.365c.284-.233.567-.517.85-.733l8.23 8.333c.234.733.484 1.467.734 2.2l-1.183 1.183z"
    />
  </svg>
)
