// @flow

import * as React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { BlockLink, ProgramCard, ProgramCardSummary } from './../../Guidelines'
import ApplicationLayout from '../../ApplicationLayout'
import routes from '../../Routes'
import RouterStore from '../../Stores/RouterStore'
import ProgramsStore from '../../Stores/ProgramsStore'

const ProgramCardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  margin: 10px 30px;
`

export default inject(
  'programsStore',
  'routerStore',
)(
  observer(
    ({
      programsStore,
      routerStore,
    }: {
      programsStore: ProgramsStore,
      routerStore: RouterStore,
    }) => {
      if (programsStore.programs.length === 0) {
        return (
          <ApplicationLayout>
            <p>Vous n'avez accès à aucune opération</p>
          </ApplicationLayout>
        )
      }

      return (
        <ApplicationLayout>
          <ProgramCardList>
            {programsStore.programs.map(oneProgram => (
              <BlockLink
                key={oneProgram.id}
                view={routes.lots_management_page}
                params={{ programId: oneProgram.id }}
                router={routerStore}
              >
                <ProgramCard>
                  <ProgramCardSummary
                    label={oneProgram.label}
                    location={oneProgram.location}
                    totalNumberOfLot={oneProgram.totalNumberOfLot}
                  />
                </ProgramCard>
              </BlockLink>
            ))}
          </ProgramCardList>
        </ApplicationLayout>
      )
    },
  ),
)
