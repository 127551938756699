// @flow

import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import {
  RobotoMedium,
  RobotoExtraBold,
} from '../../Guidelines/Typography/Fonts'
import { CurrencyInput } from '../../Guidelines/components/Form'
import { Colors } from '../../Guidelines/Colors'
import LotStatusDot from './LotStatusDot'
import Lot from './Lot'

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
  margin-top: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 8px;
`

const Table = styled.table`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  table-layout: fixed;
  user-select: none;
  border-spacing: 0;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 20px;
  text-align: center;
`

const TableForThead = styled(Table)`
  height: 20px;
  padding: 0 10px;
`

const Th = styled.th`
  padding: 0;
  color: #8591a9;
  line-height: 20px;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
`

const Thead = styled.thead``

const Tbody = styled.tbody`
  background-color: #fff;
  &:not(:last-child):after {
    height: 10px;
    display: table-row;
    content: ' ';
    background-color: #ebecee;
  }
`

const Tr = styled.tr`
  tbody &:last-child {
    box-shadow: 0 2px 4px -4px rgba(0, 0, 0, 0.5);
  }

  &:first-child td:first-child {
    pointer-events: none;
    color: #8591a9;
    text-transform: uppercase;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: dashed #e5e5e5 1px;
    box-shadow: 0 2px 4px -4px rgba(0, 0, 0, 0.5);
  }

  &:hover td:last-child:before {
    box-sizing: border-box;
    pointer-events: none;
    content: ' ';
    position: absolute;
    left: 96px;
    right: 0px;

    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    display: block;
    height: 40px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  }
`

const Td = styled.td`
  background-color: transparent;
  padding: 0;

  tbody & {
    display: table-cell;

    span {
      padding: 10px 0;
      line-height: 20px;
      text-align: center;

      color: ${({ isReadOnly }) =>
        isReadOnly ? Colors.greyAlmostLight : Colors.greyBlue};
    }
  }

  tbody tr:first-child &:last-child {
    border-top-right-radius: 4px;
  }

  tbody tr:last-child &:last-child {
    border-bottom-right-radius: 4px;
  }
`

const TdInput = styled(Td)`
  display: table-cell;
`

const Input = styled(CurrencyInput)`
  width: 100%;
  border-color: transparent;
  background-color: transparent;
  font-family: ${({ valueHaveChanged }) =>
    valueHaveChanged ? RobotoExtraBold.fontFamily : RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${({ valueHaveChanged }) =>
    valueHaveChanged ? RobotoExtraBold.fontWeight : RobotoMedium.fontWeight};
  padding: 0;
  height: 40px;
  text-align: center;
  margin: 0;

  border-color: ${({ isValidValue }) =>
    !isValidValue ? Colors.pinkBold : null};

  &:disabled {
    color: ${Colors.greyAlmostLight};
  }
`

const floorLevelLabel = (floorLevel: number) => {
  if (floorLevel === 0) {
    return 'RDC'
  }

  return `${floorLevel}E`
}

const sortByLotLabel = (lotA: Lot, lotB: Lot) => {
  if (lotA.label < lotB.label) {
    return -1
  } else if (lotA.label > lotB.label) {
    return 1
  }

  return 0
}

type Props = {
  lots: Lot[],
  selectedBuildingId: string,
  onLotPriceIncludingTaxes55Changed: (
    lotId: string,
    newLotPriceIncludingTaxes55: number,
  ) => void,
  onLotPriceIncludingTaxes20Changed: (
    lotId: string,
    newLotPriceIncludingTaxes20: number,
  ) => void,
}

const getAvailableFloorLevels = (lots: Lot[]): number[] => {
  const availableFloorLevels = new Set()

  lots.forEach(oneLot => {
    availableFloorLevels.add(oneLot.floorLevel)
  })

  return [...availableFloorLevels].sort()
}

const LotPricesBulkEditionInput = ({
  lots,
  selectedBuildingId,
  onLotPriceIncludingTaxes20Changed,
  onLotPriceIncludingTaxes55Changed,
}: Props) => {
  const lotsInBuilding = lots.filter(oneLot =>
    oneLot.isInBuiding(selectedBuildingId),
  )

  const availableFloorLevelsInSelectedBuilding = getAvailableFloorLevels(
    lotsInBuilding,
  )

  return (
    <React.Fragment>
      <TableForThead>
        <Thead>
          <Tr>
            <Th width="auto">Etage</Th>
            <Th width="auto">Lot</Th>
            <Th width="auto">Status</Th>
            <Th width="128px">TVA 20 %</Th>
            <Th width="128px">TVA 5,5 %</Th>
          </Tr>
        </Thead>
      </TableForThead>
      <Wrapper>
        <Table>
          <Thead>
            <Tr>
              <Th width="auto" />
              <Th width="auto" />
              <Th width="auto" />
              <Th width="128px" />
              <Th width="128px" />
            </Tr>
          </Thead>

          {availableFloorLevelsInSelectedBuilding.map(oneFloorLevel => {
            const lotsAtThisLevel = lotsInBuilding
              .filter(oneLot => oneLot.isAtFloor(oneFloorLevel))
              .sort(sortByLotLabel)

            return (
              <Tbody key={oneFloorLevel}>
                {lotsAtThisLevel.map((oneLot, lotIndex) => {
                  return (
                    <Tr key={oneLot.lotId}>
                      {lotIndex === 0 && (
                        <Td rowSpan={lotsAtThisLevel.length}>
                          <span>{floorLevelLabel(oneFloorLevel)}</span>
                        </Td>
                      )}

                      <Td isReadOnly={!oneLot.isEditable()}>
                        <span>{oneLot.label}</span>
                      </Td>

                      <Td>
                        <span>
                          <LotStatusDot value={oneLot.status} />
                        </span>
                      </Td>

                      <TdInput>
                        <Input
                          defaultValue={oneLot.pendingPriceIncludingTaxes20}
                          onValueChange={({ floatValue }) => {
                            onLotPriceIncludingTaxes20Changed(
                              oneLot.lotId,
                              floatValue,
                            )
                          }}
                          valueHaveChanged={
                            oneLot.isPriceIncludingTaxes20Changed
                          }
                          isValidValue={
                            oneLot.haveValidNewPriceIncludingTaxes20
                          }
                          disabled={!oneLot.isEditable()}
                        />
                      </TdInput>

                      <TdInput>
                        <Input
                          defaultValue={oneLot.pendingPriceIncludingTaxes55}
                          onValueChange={({ floatValue }) => {
                            onLotPriceIncludingTaxes55Changed(
                              oneLot.lotId,
                              floatValue,
                            )
                          }}
                          valueHaveChanged={
                            oneLot.isPriceIncludingTaxes55Changed
                          }
                          isValidValue={
                            oneLot.haveValidNewPriceIncludingTaxes55
                          }
                          disabled={!oneLot.isEditable()}
                        />
                      </TdInput>
                    </Tr>
                  )
                })}
              </Tbody>
            )
          })}
        </Table>
      </Wrapper>
    </React.Fragment>
  )
}

export default observer(LotPricesBulkEditionInput)
