// @flow
import { computed, observable } from 'mobx'
import CustomerSummary from './CustomerSummary'
import Configuration from './Configuration'

export type LotLoan =
  | 'no_action_initiated'
  | 'loan_simulation_done'
  | 'loan_granted'
export type LotVat = 20 | 5.5

export default class CommercialOffer {
  @observable vat: LotVat = 20
  @observable loan: LotLoan = 'no_action_initiated'
  @observable customer: ?CustomerSummary = null
  @observable lotPriceIncludingVAT: number = 0
  @observable configuration: ?Configuration = null
  @observable totalPriceIncludingVAT: number = 0

  constructor(
    vat: LotVat,
    loan: LotLoan,
    customer: ?CustomerSummary,
    lotPriceIncludingVAT: number,
    configuration: ?Configuration,
    totalPriceIncludingVAT: number,
  ) {
    this.vat = vat
    this.loan = loan
    this.customer = customer
    this.configuration = configuration
    this.lotPriceIncludingVAT = lotPriceIncludingVAT
    this.totalPriceIncludingVAT = totalPriceIncludingVAT
  }

  updateConfiguration(
    configurationId: string,
    configurationOptionPrice: number,
    receiptUrl: string,
  ): void {
    this.configuration = new Configuration(
      configurationId,
      configurationOptionPrice,
      receiptUrl,
    )
  }

  updateFinancialInformations(
    vat: LotVat,
    loan: LotLoan,
    lotPriceIncludingVAT: number,
    totalPriceIncludingVAT: number,
  ): void {
    this.vat = vat
    this.loan = loan
    this.lotPriceIncludingVAT = lotPriceIncludingVAT
    this.totalPriceIncludingVAT = totalPriceIncludingVAT
  }

  updateCustomer(firstName: string, lastName: string): void {
    if (!this.customer) {
      throw new Error('No current customer for updating')
    }
    const customerId = this.customer.customerId
    this.customer = new CustomerSummary(customerId, firstName, lastName)
  }

  switchVat(): void {
    this.vat = this.vat === 20 ? 5.5 : 20
  }

  @computed get loanLabel(): ?string {
    if (this.loan === 'no_action_initiated') {
      return null
    } else if (this.loan === 'loan_simulation_done') {
      return 'Simulation'
    } else if (this.loan === 'loan_granted') {
      return 'Prêt obtenu'
    } else {
      return null
    }
  }

  @computed get customerName(): ?{ firstName: string, lastName: string } {
    return this.customer
      ? {
          firstName: this.customer.firstName,
          lastName: this.customer.lastName,
        }
      : null
  }

  @computed get configurationOptionPrice(): ?number {
    return this.configuration
      ? this.configuration.configurationOptionPrice
      : null
  }

  @computed get configurationId(): ?string {
    return this.configuration ? this.configuration.configurationId : null
  }

  @computed get receiptUrl(): ?string {
    return this.configuration ? this.configuration.receiptUrl : null
  }

  @computed get customerId() {
    return this.customer ? this.customer.customerId : null
  }
}
