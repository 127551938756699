// @flow

import React from 'react'
import ProgramParameterSwitch from './ProgramParameterSwitch'

const ProgramPriceIncludeParkingLotSwitch = ({
  isPriceIncludeParkingLot,
  disabled = false,
  onChange,
}: {
  isPriceIncludeParkingLot: boolean,
  disabled?: boolean,
  onChange: boolean => void,
}) => (
  <ProgramParameterSwitch
    description="Le prix du stationnement est inclus dans le prix des logements ?"
    onLabel="Oui"
    offLabel="Non"
    toggled={isPriceIncludeParkingLot}
    disabled={disabled}
    onSwitch={toggled => onChange(toggled)}
  />
)

export default ProgramPriceIncludeParkingLotSwitch
