// @flow

export default class Building {
  +id: string
  +label: string

  constructor(id: string, label: string) {
    this.id = id
    this.label = label

    Object.freeze(this)
  }

  is(lookedId: string): boolean {
    return this.id === lookedId
  }
}
