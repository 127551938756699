// @flow

import { computed, observable } from 'mobx'

export type LotStatus =
  | 'unavailable'
  | 'available'
  | 'optioned'
  | 'booked'
  | 'sold'

export default class Lot {
  +lotId: string
  +status: LotStatus
  +label: string
  +orientation: string
  +buidldingId: string
  +floorLevel: number

  @observable _originalPriceIncludingTaxes55: number = 0
  @observable _originalPriceIncludingTaxes20: number = 0

  @observable _newPriceIncludingTaxes55: ?number = null
  @observable _newPriceIncludingTaxes20: ?number = null

  constructor(
    lotId: string,
    status: LotStatus,
    label: string,
    buidldingId: string,
    floorLevel: number,
    priceIncludingTaxes55: number,
    priceIncludingTaxes20: number,
  ) {
    this.lotId = lotId
    this.status = status
    this.label = label
    this.buidldingId = buidldingId
    this.floorLevel = floorLevel
    this._originalPriceIncludingTaxes55 = priceIncludingTaxes55
    this._originalPriceIncludingTaxes20 = priceIncludingTaxes20
  }

  is(lookupId: string): boolean {
    return this.lotId === lookupId
  }

  isInBuiding(buidldingId: string): boolean {
    return this.buidldingId === buidldingId
  }

  isAtFloor(floorLevel: number): boolean {
    return this.floorLevel === floorLevel
  }

  isEditable(): boolean {
    return this.status !== 'sold'
  }

  changePriceIncludingTaxes55(newPriceIncludingTaxes55: ?number): void {
    if (!this.isEditable()) {
      throw new Error(`The lot "${this.lotId}" is not editable`)
    }

    if (newPriceIncludingTaxes55 === this._originalPriceIncludingTaxes55) {
      this._newPriceIncludingTaxes55 = null
    } else if (
      newPriceIncludingTaxes55 === undefined ||
      newPriceIncludingTaxes55 === null
    ) {
      this._newPriceIncludingTaxes55 = 0
    } else {
      this._newPriceIncludingTaxes55 = newPriceIncludingTaxes55
    }
  }

  changePriceIncludingTaxes20(newPriceIncludingTaxes20: ?number): void {
    if (!this.isEditable()) {
      throw new Error(`The lot "${this.lotId}" is not editable`)
    }

    if (newPriceIncludingTaxes20 === this._originalPriceIncludingTaxes20) {
      this._newPriceIncludingTaxes20 = null
    } else if (
      newPriceIncludingTaxes20 === undefined ||
      newPriceIncludingTaxes20 === null
    ) {
      this._newPriceIncludingTaxes20 = 0
    } else {
      this._newPriceIncludingTaxes20 = newPriceIncludingTaxes20
    }
  }

  resetPriceIncludingTaxes55(): void {
    this._newPriceIncludingTaxes55 = null
  }

  resetPriceIncludingTaxes20(): void {
    this._newPriceIncludingTaxes20 = null
  }

  commitPricesChange(): void {
    const newPriceIncludingTaxes55 = this._newPriceIncludingTaxes55
    if (newPriceIncludingTaxes55) {
      this._originalPriceIncludingTaxes55 = newPriceIncludingTaxes55
      this._newPriceIncludingTaxes55 = null
    }

    const newPriceIncludingTaxes20 = this._newPriceIncludingTaxes20
    if (newPriceIncludingTaxes20) {
      this._originalPriceIncludingTaxes20 = newPriceIncludingTaxes20
      this._newPriceIncludingTaxes20 = null
    }
  }

  @computed get pendingPriceIncludingTaxes55(): number {
    return this._newPriceIncludingTaxes55 || this._originalPriceIncludingTaxes55
  }

  @computed get pendingPriceIncludingTaxes20(): number {
    return this._newPriceIncludingTaxes20 || this._originalPriceIncludingTaxes20
  }

  @computed get isPriceIncludingTaxes55Changed(): boolean {
    return this._newPriceIncludingTaxes55 !== null
  }

  @computed get isPriceIncludingTaxes20Changed(): boolean {
    return this._newPriceIncludingTaxes20 !== null
  }

  @computed get isPricesChanged(): boolean {
    return (
      this.isPriceIncludingTaxes55Changed || this.isPriceIncludingTaxes20Changed
    )
  }

  @computed get haveValidNewPriceIncludingTaxes55(): boolean {
    if (this._newPriceIncludingTaxes55 === null) {
      return true
    }

    return this._isValidPrice(this._newPriceIncludingTaxes55)
  }

  @computed get haveValidNewPriceIncludingTaxes20(): boolean {
    if (this._newPriceIncludingTaxes20 === null) {
      return true
    }

    return this._isValidPrice(this._newPriceIncludingTaxes20)
  }

  @computed get haveValidNewPrice(): boolean {
    return (
      this.haveValidNewPriceIncludingTaxes20 &&
      this.haveValidNewPriceIncludingTaxes55
    )
  }

  _isValidPrice(price: ?number): boolean {
    return price !== undefined && price !== null && price > 0
  }
}
