// @flow

import React from 'react'
import { Route } from 'mobx-router'
import ProgramSelectionPage from './Ui/ProgramSelectionPage'
import LoginPage from './Ui/LoginPage'
import LotsManagementPage from './Ui/LotsManagementPage'
import { RootStore } from './RootStore'
import LotPricesBulkEditionPage from './Ui/LotPricesBulkEditionPage'

// FIXME: Create an 'AuthenticatedRoute' object
const shouldBeLogged = (
  callback: (
    route: Route,
    params: { [keys: string]: any },
    store: RootStore,
    queryParams: { [keys: string]: any },
  ) => void | boolean | Promise<boolean> | Promise<void>,
) => (
  route: Route,
  params: { [keys: string]: any },
  store: RootStore,
  queryParams: { [keys: string]: any },
) => {
  if (!store.authenticationStore.loggedIn) {
    store.uiStore.redirectToLoginPage()

    return false
  }

  return callback ? callback(route, params, store, queryParams) : true
}

const routes = {
  root_page: new Route({
    path: '/',
    component: React.createElement(() => null),
    beforeEnter: shouldBeLogged((route, params, store, queryParams) => {
      store.routerStore.goTo(routes.program_selection_page)

      return false
    }),
    onEnter: (route, params, store) => {
      store.routerStore.goTo(routes.program_selection_page)
    },
  }),

  login_page: new Route({
    path: '/login',
    component: <LoginPage />,
  }),

  program_selection_page: new Route({
    path: '/programs',
    component: <ProgramSelectionPage />,
    beforeEnter: shouldBeLogged(
      async (
        route: Route,
        params: { [keys: string]: any },
        store: RootStore,
      ) => {
        await store.programsStore.fetchPrograms()
      },
    ),
  }),

  lots_management_page: new Route({
    path: '/program/:programId/lots',
    component: <LotsManagementPage />,
    beforeEnter: shouldBeLogged(
      async (
        route: Route,
        params: { [keys: string]: any },
        store: RootStore,
      ) => {
        const programId = params.programId
        if (!programId) {
          throw new Error('The programId is missing in the route')
        }

        await store.programsStore.fetchPrograms()
        await store.lotsStore.fetchLotsByProgram(programId)
      },
    ),
    onParamsChange: (route, params) => {
      console.warn('TODO: fetch program lots again (program changed)', {
        route,
        params,
      })
    },
  }),

  lots_prices_bulk_edition_page: new Route({
    path: '/program/:programId/lots/prices',
    component: <LotPricesBulkEditionPage />,
    beforeEnter: shouldBeLogged(
      async (
        route: Route,
        params: { [keys: string]: any },
        store: RootStore,
      ) => {
        const programId = params.programId
        if (!programId) {
          throw new Error('The programId is missing in the route')
        }

        await store.programsStore.fetchPrograms()
        await store.lotPricesBulkEditionStore.fetchLotsByProgram(programId)
      },
    ),
    beforeExit: (
      route: Route,
      params: { [keys: string]: any },
      store: RootStore,
    ) => {
      if (!store.lotPricesBulkEditionStore.somePriceHaveChanged) {
        return true
      }

      return window.confirm(
        "🤔 Vous êtes sur le point de quitter la page d'édition. Les changements de prix non sauvegardés seront définitivement perdus, merci de confirmer votre choix.",
      )
    },
    onParamsChange: (route, params) => {
      console.warn('TODO: fetch program lots again (program changed)', {
        route,
        params,
      })
    },
  }),
}

export default routes
