// @flow

import { action, observable, runInAction, computed } from 'mobx'
import { RootStore } from '../../RootStore'
import Lot from './Lot'
import Program from '../../Domain/Program'

export default class LotPricesBulkEditionStore {
  +rootStore: RootStore

  @observable programVatIsSubmitted: boolean = false
  @observable programInludeParkingLotIsSubmitted: boolean = false

  @observable programId: ?string = null
  @observable lots: Lot[] = []

  @observable _selectedBuildingId: ?string = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action filterVisiblePriceByBuildingId(buildingId: string) {
    const program = this._getCurrentProgram()

    if (!program.haveABuildingWithId(buildingId)) {
      throw new Error(
        `The building "${buildingId}" does not belong to the active program "${this
          .programId || ''}"`,
      )
    }

    this._selectedBuildingId = buildingId
  }

  @action async fetchLotsByProgram(programId: string) {
    const program = this.rootStore.programsStore.getProgram(programId)

    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}/lots`,
    )

    runInAction(() => {
      this.programId = programId

      this.lots = response.data.map(
        oneLotPayload =>
          new Lot(
            oneLotPayload.lotId,
            oneLotPayload.status,
            oneLotPayload.label,
            oneLotPayload.buildingId,
            oneLotPayload.floorNumber,
            oneLotPayload.priceIncludingTaxes55,
            oneLotPayload.priceIncludingTaxes20,
          ),
      )

      this._selectedBuildingId = program.getFirstBuilding().id
    })
  }

  @action changeLotPriceIncludingTaxes55(
    lotId: string,
    newPriceIncludingTaxes55: number,
  ): void {
    const lot = this.lots.find(oneLot => oneLot.is(lotId))
    if (!lot) {
      throw new Error(`Unable to find lot "${lotId}"`)
    }

    lot.changePriceIncludingTaxes55(newPriceIncludingTaxes55)
  }

  @action changeLotPriceIncludingTaxes20(
    lotId: string,
    newPriceIncludingTaxes20: number,
  ): void {
    const lot = this.lots.find(oneLot => oneLot.is(lotId))
    if (!lot) {
      throw new Error(`Unable to find lot "${lotId}"`)
    }

    lot.changePriceIncludingTaxes20(newPriceIncludingTaxes20)
  }

  @action async saveNewLotPrices() {
    const programId = this.programId
    if (!programId) {
      throw new Error(`No program selected`)
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${programId}/lots/prices`,
      {
        lots: this.lots
          .filter(oneLot => oneLot.isEditable())
          .map(oneLot => ({
            lotId: oneLot.lotId,
            priceIncludingTaxes55: oneLot.pendingPriceIncludingTaxes55,
            priceIncludingTaxes20: oneLot.pendingPriceIncludingTaxes20,
          })),
      },
    )

    runInAction(() => {
      this.lots.forEach(oneLot => oneLot.commitPricesChange())
    })
  }

  @action async saveProgramVat(newVat: 5.5 | 20) {
    const program = this._getCurrentProgram()

    this.programVatIsSubmitted = true

    const oldVat = program.vat
    program.changeVat(newVat)

    try {
      await this.rootStore.authenticationStore.httpClient.put(
        `/api/seller/program/${program.id}/vat`,
        {
          vat: newVat,
        },
      )
    } catch (error) {
      runInAction(() => {
        program.changeVat(oldVat)
      })

      throw error
    } finally {
      runInAction(() => {
        this.programVatIsSubmitted = false
      })
    }
  }

  @action async saveProgramPriceIncludeParkingLot(
    newPriceIncludeParkingLot: boolean,
  ) {
    const program = this._getCurrentProgram()

    this.programInludeParkingLotIsSubmitted = true

    const oldPriceIncludeParkingLot = program.priceIncludeParkingLot
    program.changePriceIncludeParkingPrice(newPriceIncludeParkingLot)

    try {
      await this.rootStore.authenticationStore.httpClient.put(
        `/api/seller/program/${program.id}/parking`,
        {
          parkingIncluded: newPriceIncludeParkingLot,
        },
      )
    } catch (error) {
      runInAction(() => {
        program.changePriceIncludeParkingPrice(oldPriceIncludeParkingLot)
      })

      throw error
    } finally {
      runInAction(() => {
        this.programInludeParkingLotIsSubmitted = false
      })
    }
  }

  @computed get programVat(): 5.5 | 20 {
    const program = this._getCurrentProgram()

    return program.vat
  }

  @computed get programPriceIncludeParkingLot(): boolean {
    const program = this._getCurrentProgram()

    return program.priceIncludeParkingLot
  }

  @computed get selectedBuildingId(): string {
    if (!this._selectedBuildingId) {
      throw new Error('The building filter havev not been initialized')
    }

    return this._selectedBuildingId
  }

  @computed get somePriceHaveChanged(): boolean {
    return this.lots.some(oneLot => oneLot.isPricesChanged)
  }

  _getCurrentProgram(): Program {
    const programId = this.programId
    if (!programId) {
      throw new Error('No program have been initialized.')
    }

    return this.rootStore.programsStore.getProgram(programId)
  }
}
