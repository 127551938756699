// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from './../../Colors'
import { RobotoMedium } from './../../Typography/Fonts'

const RadioLabel = styled.label`
  width: max-content;
  padding: 0 0 0 10px;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  color: ${Colors.greyRegular};
  transition: color ease-in-out 0.25s;
  cursor: pointer;
`

const RadioButton = styled.input.attrs({ type: 'radio' })`
  flex: none;
  appearance: none;
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin: 0;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #bbbbbb;
  transition: background-color ease-in-out 0.25s, border-color ease-in-out 0.25s;

  &:checked {
    background-color: ${Colors.green};
    border: 1px solid ${Colors.green};
  }

  &:checked ~ ${RadioLabel} {
    color: ${Colors.blackUltraBold};
  }
`

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
  position: relative;
  margin: 0;

  &.enabled {
    cursor: pointer;

    &:hover ${RadioButton} {
      cursor: pointer;
    }

    &:hover ${RadioLabel} {
      color: ${Colors.blackUltraBold};
    }
  }

  &.disabled {
    cursor: default;

    &:hover ${RadioButton} {
      cursor: default;
    }
  }
`

const RadioInput = ({
  value,
  checked,
  name,
  label,
  onChange,
  disabled = false,
}: {
  value: any,
  checked: boolean,
  name: string,
  label: React.Node,
  disabled?: boolean,
  onChange: (value: any) => void,
}) => (
  <RadioWrapper
    onClick={() => {
      if (disabled) {
        return
      }

      onChange(value)
    }}
    className={disabled ? 'disabled' : 'enabled'}
  >
    <RadioButton
      onChange={() => {
        if (disabled) {
          return
        }

        onChange(value)
      }}
      checked={checked}
      value={value}
      name={name}
      className={disabled ? 'disabled' : 'enabled'}
    />

    <RadioLabel>{label}</RadioLabel>
  </RadioWrapper>
)

export default RadioInput

const RadioInputList = styled.div`
  & ${RadioWrapper} {
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }
  }
`

export { RadioInputList }
