// @flow

import * as React from 'react'
import styled from 'styled-components'
import CustomDragLayer from './CustomDragLayer'

export const KanbanWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export const KanbanLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const KanbanColumnsContainer = styled.div`
  display: flex;
  margin: 25px 0 0 0;
  overflow-y: auto;
  height: 100%;
`

const KanbanColumnsLayout = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0 20px 30px 20px;
`

export const Kanban = ({ children }: { children: React.Node }) => (
  <KanbanColumnsContainer>
    <KanbanColumnsLayout>{children}</KanbanColumnsLayout>
  </KanbanColumnsContainer>
)

export const DragDropKanban = ({ children }: { children: React.Node }) => (
  <Kanban>
    {children}
    <CustomDragLayer />
  </Kanban>
)
