// @flow

// import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../Colors'
import { RobotoMedium } from '../Typography/Fonts'

export const Separator = styled.div`
  height: 0px;
  border-top: 1px dashed ${Colors.greyRegular};
  margin: 20px 0;
`
export const Colomn = styled.div`
  position: relative;
  height: 100%;
  min-width: ${({ large }) => (large ? '564px' : '256px')};

  margin: 0 15px;
  padding: ${({ large }) => (large ? '0' : '20px 0 30px 0')};

  width: ${({ large }) => (large ? '564px' : 'auto')};
`

export const ColomnsLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: max-content;
  margin: auto;
  padding: 30px 0;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  font-size: 14px;
  color: ${Colors.greyBlue};

  ${Colomn}:first-child {
    text-align: right;
  }

  ${Colomn}:last-child {
    text-align: left;
  }
`
