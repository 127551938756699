// @flow

import React from 'react'
import styled from 'styled-components'
import SwitchInput from '../../Guidelines/components/Form/SwitchInput'
import { RobotoMedium } from '../../Guidelines/Typography/Fonts'
import { Colors } from '../../Guidelines/Colors'

const Container = styled.div`
  max-width: 256px;
`
const Description = styled.p`
  margin: 0 0 10px 0;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  font-size: 14px;
  color: ${Colors.greyBlue};
  line-height: 1.43;
`

const ProgramParameterSwitch = ({
  description,
  onLabel,
  offLabel,
  toggled = false,
  disabled = false,
  onSwitch,
}: {|
  description: string,
  onLabel: string,
  offLabel: string,
  toggled?: boolean,
  disabled?: boolean,
  onSwitch: (toggled: boolean) => void,
|}) => {
  return (
    <Container>
      <Description>{description}</Description>

      <SwitchInput
        onLabel={onLabel}
        offLabel={offLabel}
        onChange={() => onSwitch(!toggled)}
        toggled={toggled}
        disabled={disabled}
        align="end"
      />
    </Container>
  )
}

export default ProgramParameterSwitch
