// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="garden__a"
        d="M10.282 8h1.436c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v5.436c0 .446-.046.607-.134.77a.909.909 0 0 1-.378.378c-.163.088-.324.134-.77.134h-1.436c-.446 0-.607-.046-.77-.134a.909.909 0 0 1-.378-.378c-.088-.163-.134-.324-.134-.77V9.282c0-.446.046-.607.134-.77a.909.909 0 0 1 .378-.378c.163-.088.324-.134.77-.134z"
      />
      <filter
        id="garden__b"
        width="150%"
        height="125%"
        x="-25%"
        y="-12.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.93573877 0 0 0 0 0.971580616 0 0 0 0 0.958305858 0 0 0 1 0"
        />
      </filter>
      <path
        id="garden__c"
        d="M4.282 8h1.436c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v5.436c0 .446-.046.607-.134.77a.909.909 0 0 1-.378.378c-.163.088-.324.134-.77.134H4.282c-.446 0-.607-.046-.77-.134a.909.909 0 0 1-.378-.378c-.088-.163-.134-.324-.134-.77V9.282c0-.446.046-.607.134-.77a.909.909 0 0 1 .378-.378c.163-.088.324-.134.77-.134z"
      />
      <filter
        id="garden__d"
        width="150%"
        height="125%"
        x="-25%"
        y="-12.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.93573877 0 0 0 0 0.971580616 0 0 0 0 0.958305858 0 0 0 1 0"
        />
      </filter>
      <path
        id="garden__e"
        d="M7.273 9.455a5 5 0 1 1 2.654-5.31A4.004 4.004 0 0 1 15 8a4 4 0 0 1-7.727 1.455z"
      />
      <filter
        id="garden__f"
        width="113.3%"
        height="116.7%"
        x="-6.7%"
        y="-8.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.93573877 0 0 0 0 0.971580616 0 0 0 0 0.958305858 0 0 0 1 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillOpacity={0} d="M0 0h16v16H0z" />
      <use fill="#D8F3E9" xlinkHref="#garden__a" />
      <use fill="#000" filter="url(#garden__b)" xlinkHref="#garden__a" />
      <path
        stroke="#62CFA8"
        strokeLinejoin="square"
        d="M10.282 8.5c-.331 0-.432.02-.535.074a.409.409 0 0 0-.173.173c-.055.103-.074.204-.074.535v5.436c0 .331.02.432.074.535.041.075.098.132.173.173.103.055.204.074.535.074h1.436c.331 0 .432-.02.535-.074a.409.409 0 0 0 .173-.173c.055-.103.074-.204.074-.535V9.282c0-.331-.02-.432-.074-.535a.409.409 0 0 0-.173-.173c-.103-.055-.204-.074-.535-.074h-1.436z"
      />
      <use fill="#D8F3E9" xlinkHref="#garden__c" />
      <use fill="#000" filter="url(#garden__d)" xlinkHref="#garden__c" />
      <path
        stroke="#62CFA8"
        strokeLinejoin="square"
        d="M4.282 8.5c-.331 0-.432.02-.535.074a.409.409 0 0 0-.173.173c-.055.103-.074.204-.074.535v5.436c0 .331.02.432.074.535.041.075.098.132.173.173.103.055.204.074.535.074h1.436c.331 0 .432-.02.535-.074a.409.409 0 0 0 .173-.173c.055-.103.074-.204.074-.535V9.282c0-.331-.02-.432-.074-.535a.409.409 0 0 0-.173-.173C6.15 8.52 6.049 8.5 5.718 8.5H4.282z"
      />
      <g>
        <use fill="#D8F3E9" xlinkHref="#garden__e" />
        <use fill="#000" filter="url(#garden__f)" xlinkHref="#garden__e" />
        <path
          stroke="#62CFA8"
          strokeLinejoin="square"
          d="M7.045 9.01l.492-.252.202.515a3.501 3.501 0 1 0 2.322-4.646l-.533.148-.093-.545A4.501 4.501 0 0 0 .5 5a4.5 4.5 0 0 0 6.545 4.01z"
        />
      </g>
      <path
        stroke="#62CFA8"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.5 15.5h15"
      />
    </g>
  </svg>
)
