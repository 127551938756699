// @flow

import axios, { Axios, type $AxiosError, type AxiosXHRConfig } from 'axios'
import { action, observable, computed, runInAction } from 'mobx'
import ClientOAuth2, { Token } from 'client-oauth2'
import { RootStore } from '../RootStore'
import routes from '../Routes'
import { TokenStorage } from './../Infra/TokenStorage'

export default class AuthenticationStore {
  +rootStore: RootStore
  +authClient: ClientOAuth2
  +tokenStorage: TokenStorage

  +httpClient: Axios

  @observable token: ?Token = null

  constructor(
    rootStore: RootStore,
    authClient: ClientOAuth2,
    tokenStorage: TokenStorage,
    apiEndpoint: string,
  ) {
    this.rootStore = rootStore
    this.authClient = authClient
    this.tokenStorage = tokenStorage

    this.httpClient = axios.create({ baseURL: apiEndpoint })
    this.httpClient.interceptors.request.use(this._signRequest.bind(this))
    this.httpClient.interceptors.response.use(
      null,
      this._checkAuthenticationError.bind(this),
    )
  }

  @computed
  get loggedIn(): boolean {
    return !!this.token
  }

  @action
  loadTokenFromStorage() {
    this.token = this.tokenStorage.read()
  }

  @action
  async performLogin(login: string, password: string) {
    let token
    try {
      token = await this.authClient.owner.getToken(login, password)
    } catch (err) {
      if (err.code !== 'EAUTH') {
        throw err
      }

      return {
        success: false,
        errors:
          err.body && err.body.error_description
            ? [{ field: null, message: err.body.error_description }]
            : [{ field: null, message: err.toString() }],
      }
    }

    if (!token) {
      throw new Error('No login error detected but no token available neither')
    }

    runInAction(() => {
      this.token = token
      this.tokenStorage.write(this.token)

      this.rootStore.routerStore.goTo(routes.program_selection_page)
    })

    return {
      success: true,
      errors: [],
    }
  }

  @action
  performLogout() {
    this.token = null
    this.tokenStorage.delete()
    this.rootStore.routerStore.goTo(routes.login_page)
  }

  _signRequest<T>(config: AxiosXHRConfig<T>): AxiosXHRConfig<T> {
    const token = this.token
    if (token) {
      if (token.expired()) {
        this.performLogout()
        throw new Error('Token expired: request canceled')
      }

      return token.sign(config)
    }

    return config
  }

  _checkAuthenticationError<T>(error: $AxiosError<T>): any {
    const { response } = error
    if (!response || response.status !== 401) {
      return Promise.reject(error)
    }

    this.performLogout()
  }
}
