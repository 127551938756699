// @flow

import React from 'react'
import styled from 'styled-components'
import { Colors, RobotoBold } from '../../Guidelines'

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  list-style: none;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Button = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  font-family: ${RobotoBold.fontFamily};
  font-weight: ${RobotoBold.fontWeight};
  font-size: 22px;
  line-height: 1.36;
  color: ${Colors.greyRegular};
  transition: color 0.25s ease-in-out;

  &.selected {
    color: ${Colors.blackUltraBold};
  }

  &:hover {
    cursor: pointer;
    color: ${Colors.green};
  }

  &:focus {
    outline: none;
  }
`

const BuildingSelector = ({
  buildings,
  selectedBuildingId,
  onChange,
}: {
  buildings: Array<{| id: string, label: string |}>,
  selectedBuildingId: string,
  onChange: (newSelectedBuilding: string) => void,
}) => {
  return (
    <List>
      {buildings.map(oneBuilding => (
        <ListItem key={oneBuilding.id}>
          <Button
            className={
              oneBuilding.id === selectedBuildingId ? 'selected' : null
            }
            onClick={e => {
              e.preventDefault()
              onChange(oneBuilding.id)
            }}
          >
            {oneBuilding.label}
          </Button>
        </ListItem>
      ))}
    </List>
  )
}

export default BuildingSelector
