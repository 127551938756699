// @flow

import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../Guidelines/Colors'

const Dot = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
`

const LotStatusDot = ({ value }: { value: string }) => {
  if (value === 'unavailable') {
    return <Dot color={Colors.green} title="Indisponible" />
  }

  if (value === 'available') {
    return <Dot color={Colors.green} title="Disponible" />
  }

  if (value === 'optioned') {
    return <Dot color={Colors.yellow} title="Optionné" />
  }

  if (value === 'booked') {
    return <Dot color={Colors.yellow} title="Réservé" />
  }

  if (value === 'sold') {
    return <Dot color={Colors.greyAlmostLight} title="Vendu" />
  }

  throw new Error(`Unknow lot status "${value}"`)
}

export default LotStatusDot
