// @flow

import * as React from 'react'
import {
  DragSource,
  ConnectDragSource,
  ConnectDragPreview,
} from 'react-dnd-cjs'
import { getEmptyImage } from 'react-dnd-html5-backend-cjs'
import { Card } from './Card'
import ItemTypes from './ItemTypes'

const cardSource = {
  canDrag(props) {
    const { toColumn } = props
    return toColumn.length > 0
  },

  beginDrag(props, monitor, component) {
    return {
      lotId: props.lotId,
      toColumn: props.toColumn,
      label: props.label,
      price: props.price,
      typology: props.typology,
      area: props.area,
      balconyArea: props.balconyArea,
      gardenArea: props.gardenArea,
      orientation: props.orientation,
      floor: props.floor,
      vat: props.vat,
      loanLabel: props.loanLabel,
      vatIsFixed: props.vatIsFixed,
      lotStatus: props.lotStatus,
      customerNames: props.customerNames,
    }
  },
}

const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    canDrag: monitor.canDrag(),
  }
}

type Props = {
  isDragging: boolean,
  canDrag: boolean,
  connectDragSource: ConnectDragSource,
  connectDragPreview?: ConnectDragPreview,
  label: string,
  typology: string,
  area: number,
  balconyArea: ?number,
  gardenArea: ?number,
  orientation: string,
  floor: number,
  lotId: string,
  onClickCard: ?(lotId: string) => void,
  vat: 20 | 5.5,
  price: number,
  loanLabel?: string,
  vatIsFixed: boolean,
  faded?: boolean,
  customerNames: ?{
    firstName: string,
    lastName: string,
  },
}

class DraggableCard extends React.Component<Props, {}> {
  componentDidMount() {
    const { connectDragPreview } = this.props
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      })
    }
  }

  render() {
    const {
      connectDragSource,
      isDragging,
      canDrag,
      label,
      typology,
      area,
      balconyArea,
      gardenArea,
      orientation,
      floor,
      onClickCard,
      lotId,
      vat,
      price,
      loanLabel,
      vatIsFixed,
      faded = false,
      customerNames,
    } = this.props

    const opacity = isDragging ? 0.8 : 1
    const filter = isDragging ? 'blur(4px)' : 'none'
    const cursor = canDrag ? 'move' : 'default'

    return connectDragSource(
      <div
        onClick={() => onClickCard && onClickCard(lotId)}
        style={{ opacity, cursor, filter }}
        data-test-id={`draggable-card-lot-${lotId}`}
      >
        <Card
          lotId={lotId}
          label={label}
          price={price}
          typology={typology}
          area={area}
          balconyArea={balconyArea}
          gardenArea={gardenArea}
          orientation={orientation}
          floor={floor}
          vat={vat}
          loanLabel={loanLabel}
          vatIsFixed={vatIsFixed}
          faded={faded}
          customerNames={customerNames}
        />
      </div>,
    )
  }
}

export default DragSource(
  ItemTypes.LOT_CARD,
  cardSource,
  collect,
)(DraggableCard)
