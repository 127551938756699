// @flow

import React from 'react'
import styled from 'styled-components'
import { Button } from '../Button'
import { Colors } from '../../Colors'
import { RobotoMedium } from '../../Typography/Fonts'
import { FakeLink } from '../../Typography/Link'
import AutocompleteField from '../Form/AutocompleteField'

const Container = styled.div`
  width: 310px;
  min-height: 290px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed ${Colors.greyLight};
  margin: 45px 0;

  &:after {
    position: absolute;
    content: 'ou';
    font-family: ${RobotoMedium.fontFamily};
    font-size: 14px;
    font-weight: ${RobotoMedium.fontWeight};
    line-height: 20px;
    color: ${Colors.greyRegular};
    top: -11px;
    padding: 0 4px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    background-color: ${Colors.white};
  }
`

const Suggestion = styled.div``
const Email = styled.div`
  color: ${Colors.greyRegular};
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
`

const SkipCustomer = styled(FakeLink)`
  position: absolute;
  bottom: 30px;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  color: ${Colors.green};
  line-height: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
`

type Customer = {
  customerId: string,
  firstName: string,
  lastName: ?string,
  email: string,
}

export default ({
  onCustomerSelected,
  customers,
  onNewCustomer,
  onSkipCustomer,
}: {
  onCustomerSelected: (customerId: string) => void,
  customers: Customer[],
  onNewCustomer: () => void,
  onSkipCustomer: () => void,
}) => {
  return (
    <Container>
      <Button onClick={() => onNewCustomer()} value="Nouveau client" />
      <Separator />
      <AutocompleteField
        valueId="customerId"
        values={customers}
        searchableFields={['firstName', 'lastName', 'email']}
        placeholder="Client existant"
        getSuggestionValue={suggestion => {
          if (suggestion.lastName) {
            return suggestion.firstName + ' ' + suggestion.lastName
          } else {
            return suggestion.firstName
          }
        }}
        onSuggestionSelected={suggestion =>
          onCustomerSelected(suggestion.customerId)
        }
        renderSuggestion={suggestion => (
          <Suggestion>
            {suggestion.firstName} {suggestion.lastName}
            <Email>{suggestion.email}</Email>
          </Suggestion>
        )}
      />
      <SkipCustomer onClick={onSkipCustomer} accented>
        Ne pas associer de client
      </SkipCustomer>
    </Container>
  )
}
