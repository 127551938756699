import React from 'react'
import styled from 'styled-components'
import { DragLayer } from 'react-dnd-cjs'
import ItemTypes from './ItemTypes'
import { DraggingCard } from './Card'

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

const Layer = styled.div.attrs(({ currentOffset }) => ({
  style: currentOffset
    ? {
        transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
      }
    : {
        display: 'none',
      },
}))``

const CustomDragLayer = props => {
  const { item, itemType, isDragging, currentOffset } = props
  const renderItem = () => {
    switch (itemType) {
      case ItemTypes.LOT_CARD:
        return (
          <DraggingCard
            label={item.label}
            price={item.price}
            typology={item.typology}
            area={item.area}
            balconyArea={item.balconyArea}
            gardenArea={item.gardenArea}
            orientation={item.orientation}
            floor={item.floor}
            vat={item.vat}
            loanLabel={item.loanLabel}
            vatIsFixed={item.vatIsFixed}
            customerNames={item.customerNames}
          />
        )
      default:
        return null
    }
  }
  if (!isDragging) {
    return null
  }
  return (
    <div style={layerStyles}>
      <Layer currentOffset={currentOffset}>{renderItem()}</Layer>
    </div>
  )
}
export default DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
}))(CustomDragLayer)
