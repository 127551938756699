// @flow

import styled from 'styled-components'
import { RobotoMedium } from './../Typography/Fonts'
import { Colors } from '../Colors'

export const Button = styled.input.attrs({ type: 'button' })`
  width: 100%;
  height: 60px;
  padding: 20px 16px;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 1.43;
  background-color: ${Colors.green};
  color: ${Colors.white};
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:disabled {
    opacity: 1;
    cursor: default;
    background-color: ${Colors.greyLight};
  }
`

export const SecondaryButton = styled.input.attrs({ type: 'button' })`
  width: min-content;
  height: 30px;
  padding: 0 10px;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 11px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 2.73;
  background-color: transparent;
  color: ${Colors.green};
  border: 1px solid ${Colors.green};
  border-radius: 4px;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:disabled {
    opacity: 1;
    cursor: default;
    color: ${Colors.greyLight};
    border-color: ${Colors.greyLight};
  }

  &:focus {
    outline: none;
  }
`
