// @flow

import styled from 'styled-components'
import { Link as MobxRouterLink } from 'mobx-router'
import { Colors } from './../Colors'

const linkStyles = ({ accented }) => `
  &,
  &:focus {
    text-decoration: underline;
    color: ${accented ? Colors.green : Colors.greyRegular};
    transition: color ease-in-out 250ms;
    cursor: pointer;
  }

  &:hover {
    color: ${Colors.green};
    text-decoration: ${accented ? 'none' : 'underline'};
  }
`

export const Link = styled(MobxRouterLink)(props => linkStyles(props))

export const BlockLink = styled(Link)`
  &,
  &:focus,
  &:hover {
    display: block;
    width: min-content;
    height: min-content;
    text-decoration: none;
    color: inherit;
    transition: none;
    cursor: pointer;
  }
`

export const FakeLink = styled.span(props => linkStyles(props))

export const NativeLink = styled.a(props => linkStyles(props))
