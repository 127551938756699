// @flow

import styled from 'styled-components'

export const Navbar = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content auto max-content;
  grid-template-rows: auto;
  grid-template-areas: 'left-area . center-area . right-area';
  align-items: center;

  width: 100%;
  height: 88px;
  margin: 0 0 10px 0;
  padding: 0 30px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
`

export const NavbarLeftArea = styled.div`
  grid-area: left-area;
  justify-self: start;
`

export const NavbarCenterArea = styled.div`
  grid-area: center-area;
  justify-self: center;
`

export const NavbarRightArea = styled.div`
  grid-area: right-area;
  justify-self: end;
`

export { BrandedBreadcrumb, BrandedBreadcrumbItem } from './BrandBreadcrumb'

export { NavbarMenu, NavbarMenuItem } from './Menu'
