// @flow

const blackUltraBold = '#222222'
const greyRegular = '#adb1c2'
const greyAlmostLight = '#bbbbbb'
const greyLight = '#e5e5e5'
const greyUltraLight = '#f6f6f6'
const greyBlue = '#8591a9'
const white = '#ffffff'
const greenLight = '#d8f3e9'
const green = '#3bc492'
const pinkBold = '#ef4872'
const yellow = '#ffd35c'

export const Colors = {
  blackUltraBold,
  greyRegular,
  greyAlmostLight,
  greyLight,
  greyUltraLight,
  greyBlue,
  white,
  greenLight,
  green,
  pinkBold,
  yellow,
}
