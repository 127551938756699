// @flow

export default class CustomerSummary {
  +customerId: string
  +firstName: string
  +lastName: string

  constructor(customerId: string, firstName: string, lastName: string) {
    this.customerId = customerId
    this.firstName = firstName
    this.lastName = lastName

    Object.freeze(this)
  }
}
