// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../../../Colors'
import { RobotoMedium, RobotoBold } from '../../../Typography/Fonts'
import Price from '../../../Typography/Price'
import Taxes from '../../../Typography/Taxes'
import TypologySvg from '../../../icons/TypologySvg'
import AreaSvg from '../../../icons/AreaSvg'
import OrientationSvg from '../../../icons/OrientationSvg'
import FloorSvg from '../../../icons/FloorSvg'
import GardenSvg from '../../../icons/GardenSvg'
import BalconySvg from '../../../icons/BalconySvg'

const LotCardContainer = styled.div`
  width: ${({ isDragging }) => (isDragging ? '312px' : '310px')};
  height: ${({ isDragging }) => (isDragging ? '122px' : '120px')};
  border-radius: 4px;
  box-shadow: ${({ isDragging }) =>
    isDragging
      ? '0 2px 16px 0 rgba(0, 0, 0, 0.25)'
      : '0 2px 4px 0 rgba(0, 0, 0, 0.1)'};
  background-color: ${Colors.white};
  margin: 0 10px 10px 10px;
  border: ${({ isDragging }) =>
    isDragging ? `solid 1px ${Colors.green}` : 'none'};

  color: ${({ faded }) => (faded ? '#adb1c2' : Colors.blackUltraBold)};
`

const Main = {
  Row: styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 50%;
    border-bottom: 1px solid ${Colors.greyLight};
  `,
  Child: styled.div`
    font-family: ${RobotoBold.fontFamily};
    font-size: 17px;
    font-weight: ${RobotoBold.fontWeight};
    line-height: 1.18;
  `,
}

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 6px;
    margin-right: 2px;
  }
  span {
    font-family: ${RobotoBold.fontFamily};
    font-size: 10px;
    font-weight: ${RobotoBold.fontWeight};
    line-height: 1;
    color: ${Colors.greyRegular};
  }
`

const Detailed = {
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 50%;
    border-radius: inherit;
    background-color: ${Colors.greyUltraLight};
  `,
  Child: styled.div`
    width: 62px;
    display: flex;
    align-items: center;
    font-family: ${RobotoMedium.fontFamily};
    font-size: 14px;
    font-weight: ${RobotoMedium.fontWeight};

    svg {
      margin-right: 4px;
    }
  `,
}

const CommercialOffer = styled.div`
  display: table;
  position: absolute;
  bottom: -10px;
  border-radius: 12px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${Colors.greyLight};
  background-color: ${Colors.white};
  padding: 0 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
`

const FloorLevel = ({ value }: { value: number }): string => {
  if (!value) {
    return 'RDC'
  }
  if (value < 0) {
    return `R-${value}`
  }

  return `R+${value}`
}

const Vat = styled.div`
  width: 100%;
  height: 10px;
  font-family: ${RobotoBold.fontFamily};
  font-size: 10px;
  font-weight: ${RobotoBold.fontWeight};
  text-align: right;
  color: ${({ vatIsFixed }) =>
    vatIsFixed ? Colors.blackUltraBold : Colors.greyRegular};
`

const CustomerName = styled.span`
  text-transform: uppercase;
  font-family: ${RobotoBold.fontFamily};
  font-size: 11px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 20px;
  color: ${Colors.blackUltraBold};
`

const NameSeparator = styled.span`
  margin: 0 4px;
  color: #dddddd;
  font-size: 11px;
  line-height: 20px;
`

const LoanLabel = styled.span`
  font-family: ${RobotoBold.fontFamily};
  font-size: 10px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 20px;
  color: ${Colors.greyRegular};
`

export const DraggingCard = ({
  lotId,
  label,
  price,
  typology,
  area,
  balconyArea,
  gardenArea,
  orientation,
  floor,
  vat,
  loanLabel,
  vatIsFixed,
  customerNames,
}: {
  lotId: string,
  label: string,
  price: number,
  typology: string,
  area: number,
  balconyArea: ?number,
  gardenArea: ?number,
  orientation: string,
  floor: number,
  vat: 20 | 5.5,
  loanLabel?: string,
  vatIsFixed: boolean,
  customerNames: ?{
    firstName: string,
    lastName: string,
  },
}) => (
  <Card
    lotId={lotId}
    label={label}
    price={price}
    typology={typology}
    area={area}
    balconyArea={balconyArea}
    gardenArea={gardenArea}
    orientation={orientation}
    floor={floor}
    isDragging
    vat={vat}
    loanLabel={loanLabel}
    vatIsFixed={vatIsFixed}
    customerNames={customerNames}
  />
)

export const Card = ({
  lotId,
  label,
  price,
  typology,
  area,
  balconyArea,
  gardenArea,
  orientation,
  floor,
  isDragging = false,
  vat,
  loanLabel,
  vatIsFixed,
  faded = false,
  customerNames,
}: {
  lotId: string,
  label: string,
  price: number,
  typology: string,
  area: number,
  balconyArea: ?number,
  gardenArea: ?number,
  orientation: string,
  floor: number,
  isDragging?: boolean,
  vat: 20 | 5.5,
  loanLabel?: string,
  vatIsFixed: boolean,
  faded?: boolean,
  customerNames: ?{
    firstName: string,
    lastName: string,
  },
}) => (
  <LotCardContainer
    isDragging={isDragging}
    data-test-id={`lot-card-${lotId}`}
    faded={faded}
  >
    <Main.Row>
      <Main.Child data-test-node-type="lot-card-label">
        <LabelWrapper>
          {label}
          {balconyArea && (
            <React.Fragment>
              <BalconySvg />
              <span>{`${Math.round(balconyArea)}m²`}</span>
            </React.Fragment>
          )}
          {gardenArea && (
            <React.Fragment>
              <GardenSvg />
              <span>{`${Math.round(gardenArea)}m²`}</span>
            </React.Fragment>
          )}
        </LabelWrapper>
      </Main.Child>
      <Main.Child>
        <div>
          <Price amount={price} />
        </div>
        <Vat vatIsFixed={vatIsFixed}>
          <Taxes amount={vat} />
        </Vat>
      </Main.Child>
      {customerNames ? (
        <CommercialOffer>
          <CustomerName>{`${customerNames.firstName.charAt(0)}. ${
            customerNames.lastName
          }`}</CustomerName>
          {loanLabel ? (
            <React.Fragment>
              <NameSeparator>|</NameSeparator>
              <LoanLabel>{loanLabel}</LoanLabel>
            </React.Fragment>
          ) : null}
        </CommercialOffer>
      ) : null}
    </Main.Row>
    <Detailed.Row>
      <Detailed.Child>
        <TypologySvg />
        {typology}
      </Detailed.Child>
      <Detailed.Child>
        <AreaSvg />
        {`${Math.round(area)}m²`}
      </Detailed.Child>
      <Detailed.Child>
        <OrientationSvg />
        {orientation}
      </Detailed.Child>
      <Detailed.Child>
        <FloorSvg />
        <FloorLevel value={floor} />
      </Detailed.Child>
    </Detailed.Row>
  </LotCardContainer>
)
