// @flow

import ClientOAuth2 from 'client-oauth2'
import url from 'url'
import UiStore from './Stores/UiStore'
import UserStore from './Stores/UserStore'
import AuthenticationStore from './Stores/AuthenticationStore'
import RouterStore from './Stores/RouterStore'
import ProgramsStore from './Stores/ProgramsStore'
import LotsStore from './Stores/LotsStore'
import { TokenStorage } from './Infra/TokenStorage'
import CookieTokenStorage from './Infra/CookieTokenStorage'
import LotPricesBulkEditionStore from './Ui/LotPricesBulkEditionPage/LotPricesBulkEditionStore'

export class RootStore {
  +apiEndpoint: string

  +uiStore: UiStore
  +routerStore: RouterStore
  +userStore: UserStore
  +authenticationStore: AuthenticationStore
  +programsStore: ProgramsStore
  +lotsStore: LotsStore
  +lotPricesBulkEditionStore: LotPricesBulkEditionStore

  router: RouterStore // Alias of routerStore to allow mobx router to works (populated by the RouterStore)

  constructor(
    apiEndpoint: string,
    authClient: ClientOAuth2,
    tokenStorage: TokenStorage,
  ) {
    this.apiEndpoint = apiEndpoint

    this.uiStore = new UiStore(this)
    this.routerStore = new RouterStore(this)
    this.authenticationStore = new AuthenticationStore(
      this,
      authClient,
      tokenStorage,
      apiEndpoint,
    )
    this.userStore = new UserStore(this)
    this.programsStore = new ProgramsStore(this)
    this.lotsStore = new LotsStore(this)
    this.lotPricesBulkEditionStore = new LotPricesBulkEditionStore(this)
  }
}

export const buildRootStore = (
  apiEndpoint: string,
  oauthClientId: string,
  oauthClientSecret: string,
): RootStore => {
  // FIXME: As we use core-js v3, check this code and comment
  // The browser version of the `url` module implement the deprecated version of the `url` API (thanks core-js v2)

  const accessTokenUri = url.format({
    ...url.parse(apiEndpoint || '/'), // eslint-disable-line node/no-deprecated-api
    ...{ pathname: '/oauth/v2/token' },
  })

  const authClient = new ClientOAuth2({
    clientId: oauthClientId,
    clientSecret: oauthClientSecret,
    accessTokenUri,
    scopes: [],
  })

  const cookieTokenStorage = new CookieTokenStorage(
    authClient,
    document.location.protocol,
    process.env.REACT_APP_AUTH_COOKIE_NAME || undefined,
  )

  return new RootStore(apiEndpoint, authClient, cookieTokenStorage)
}
