// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Link, FakeLink } from '../../Typography/Link'
import { RobotoMedium } from '../../Typography/Fonts'
import { Colors } from './../../Colors'

export const NavbarMenu = ({ children }: { children: React.Node }) => (
  <React.Fragment>{children}</React.Fragment>
)

export const NavbarMenuItem = styled.span`
  display: inline-block;
  padding: 10px 20px;

  &:last-child {
    padding-right: 0;
  }

  & ${Link}, & ${FakeLink} {
    display: inline-block;
    padding: 10px 20px;
    &,
    &:focus {
      font-family: ${RobotoMedium.fontFamily};
      font-size: 16px;
      font-weight: ${RobotoMedium.fontWeight};
      color: ${Colors.blackUltraBold};
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`
