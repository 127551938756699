// @flow

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import { initErrorReportingAgent } from './ErrorReporting'
import SellerApplication from './SellerApplication'

const sentryDSN = process.env.REACT_APP_SENTRY_DSN
if (!sentryDSN) {
  throw new Error('The sentry dsn environment variable is missing')
}
const buildEnvironment = process.env.REACT_APP_BUILD_ENVIRONMENT
if (!buildEnvironment) {
  throw new Error('The build environment environment variable is missing')
}

initErrorReportingAgent(sentryDSN, buildEnvironment)

const rootElement = document.getElementById('root')
if (!rootElement) {
  throw new Error('Unable to find the #root element')
}

ReactDOM.render(
  <React.Fragment>
    <SellerApplication />
  </React.Fragment>,
  rootElement,
)
