// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../../Colors'
import { RobotoBold } from '../../Typography/Fonts'

const StyledFieldset = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ alignContent }) =>
    alignContent === 'center' ? 'center' : 'flex-start'};
  min-height: 60px;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: white;
  color: ${Colors.greyRegular};
`

const StyledLegend = styled.div`
  position: absolute;
  top: -6px;
  margin: ${({ alignContent }) =>
    alignContent === 'center' ? '0 0' : '0 10px'};
  padding: 0 4px;
  background-color: white;
  font-size: 10px;
  font-family: ${RobotoBold.fontFamily};
  font-weight: ${RobotoBold.fontWeight};
  color: ${Colors.greyRegular};
  text-align: ${({ alignContent }) =>
    alignContent === 'center' ? 'center' : 'left'};
  border-radius: 100px;
`

const Fieldset = ({
  children,
  legend,
  alignContent,
  ...props
}: {
  children: React.Node,
  legend: React.Node,
  alignContent?: 'start' | 'center',
}) => {
  alignContent = alignContent || 'start'

  return (
    <StyledFieldset {...props} alignContent={alignContent}>
      <StyledLegend alignContent={alignContent}>{legend}</StyledLegend>

      {children}
    </StyledFieldset>
  )
}

export default Fieldset
