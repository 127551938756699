// @flow

import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'mobx-router'
import {
  ApplicationContainer,
  ApplicationHeader,
  ApplicationMainView,
  BrandedBreadcrumb,
  BrandedBreadcrumbItem,
  CusthomeTextAndPictoLogo,
  Navbar,
  NavbarLeftArea,
  NavbarRightArea,
  NavbarMenu,
  NavbarMenuItem,
  FakeLink,
} from './Guidelines'
import routes from './Routes'
import UserStore from './Stores/UserStore'
import AuthenticationStore from './Stores/AuthenticationStore'
import RouterStore from './Stores/RouterStore'

export default inject(
  'routerStore',
  'userStore',
  'authenticationStore',
)(
  observer(
    ({
      children,
      routerStore,
      userStore,
      authenticationStore,
      breadcrumb,
      breadcrumbCallToAction,
    }: {
      children: React.Node,
      routerStore: RouterStore,
      userStore: UserStore,
      authenticationStore: AuthenticationStore,
      breadcrumb?: React.Node,
      breadcrumbCallToAction?: React.Node,
    }) => (
      <ApplicationContainer>
        <ApplicationHeader>
          <Navbar>
            <NavbarLeftArea>
              <BrandedBreadcrumb callToAction={breadcrumbCallToAction}>
                <BrandedBreadcrumbItem key="homepage">
                  <Link
                    view={routes.program_selection_page}
                    router={routerStore}
                  >
                    <CusthomeTextAndPictoLogo width="140px" />
                  </Link>
                </BrandedBreadcrumbItem>
                {React.Children.map(breadcrumb, (item, i) => {
                  const itemKey = item.key
                  if (!itemKey) {
                    throw new Error(
                      'You should add a `key` attribute on each breadcrumb item',
                    )
                  }

                  return (
                    <BrandedBreadcrumbItem key={itemKey}>
                      {item}
                    </BrandedBreadcrumbItem>
                  )
                })}
              </BrandedBreadcrumb>
            </NavbarLeftArea>
            <NavbarRightArea>
              <NavbarMenu>
                {userStore.loggedIn ? (
                  <NavbarMenuItem>
                    <FakeLink
                      onClick={() => authenticationStore.performLogout()}
                    >
                      Se déconnecter
                    </FakeLink>
                  </NavbarMenuItem>
                ) : null}
              </NavbarMenu>
            </NavbarRightArea>
          </Navbar>
        </ApplicationHeader>

        <ApplicationMainView>{children}</ApplicationMainView>
      </ApplicationContainer>
    ),
  ),
)
