// @flow
import * as React from 'react'
import { DropTarget, ConnectDropTarget } from 'react-dnd-cjs'
import ColumnCard, { ColumnCardDroppableArea } from './ColumnCard'
import ItemTypes from './ItemTypes'
import SwitchInput from '../../Form/SwitchInput'
import ColumnFooter from './ColumnFooter'
import { FakeLink } from './../../../Typography/Link'
import Taxes from '../../../Typography/Taxes'

const cardTarget = {
  canDrop(props, monitor) {
    const { toColumn } = monitor.getItem()
    return toColumn.includes(props.columnStatus)
  },

  drop(props, monitor, component) {
    const { lotId, lotStatus } = monitor.getItem()
    const { onDrop, columnStatus } = props
    onDrop(lotId, columnStatus, lotStatus)
  },
}

const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  }
}

type Props = {|
  isOver: boolean,
  canDrop: boolean,
  connectDropTarget: ConnectDropTarget,
  children: React.Node,
  title: string,
  displayedVat: 20 | 5.5,
  onSwitch: () => void,
  columnStatus: string,
  collapsable?: boolean,
|}

const DroppableColumn = ({
  isOver,
  canDrop,
  connectDropTarget,
  children,
  title,
  displayedVat,
  onSwitch,
  columnStatus,
  collapsable = false,
}: Props) => {
  const [collapsed, setCollapsed] = React.useState(collapsable === true)

  return connectDropTarget(
    <div
      style={{ position: 'relative' }}
      data-test-id={`droppable-column-${columnStatus}`}
    >
      <ColumnCardDroppableArea
        canDrop={canDrop}
        isOver={isOver}
        title={title}
        collapsed={collapsed}
      />

      <ColumnCard
        collapsable={collapsable}
        title={title}
        collapsed={collapsed}
        onClick={() => setCollapsed(false)}
      >
        {children}
      </ColumnCard>

      {columnStatus === 'available' ? (
        <ColumnFooter>
          <SwitchInput
            onLabel={<Taxes amount={20} />}
            offLabel={<Taxes amount={5.5} />}
            onChange={onSwitch}
            toggled={displayedVat === 20}
          />
        </ColumnFooter>
      ) : null}

      {collapsable ? (
        <ColumnFooter visible={!collapsed}>
          <FakeLink onClick={() => setCollapsed(true)}>
            masquer la colonne
          </FakeLink>
        </ColumnFooter>
      ) : null}
    </div>,
  )
}

export default DropTarget(
  ItemTypes.LOT_CARD,
  cardTarget,
  collect,
)(DroppableColumn)
