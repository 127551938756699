// @flow

import styled, { keyframes } from 'styled-components'
import FormattedInputBase from 'react-number-format'
import { RobotoMedium, RobotoBold } from './../../Typography/Fonts'
import { Colors } from './../../Colors'

export const onAutoFillStart = keyframes`from {} to {}`
export const onAutoFillCancel = keyframes`0% {} 100% {}`

export const Input = styled.input`
  width: 100%;
  margin-top: 10px;
  padding: 16px 16px;
  line-height: 26px;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 17px;
  font-weight: ${RobotoMedium.fontWeight};
  background-color: white;
  border-radius: 4px;
  border: solid 1px ${({ errored }) => (errored ? Colors.pinkBold : '#dddddd')};
  color: ${Colors.blackUltraBold};
  transition: border-color ease-in-out 200ms;

  &::placeholder {
    color: ${Colors.greyRegular};
  }

  &:first-child {
    margin-top: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 4px 1000px #f6f6f6 inset;
  }

  &:focus {
    outline: none;
    border: 1px solid ${Colors.green};
  }

  &:disabled {
    color: ${Colors.greyRegular};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${Colors.blackUltraBold};
  }

  /**
   * Theses -webkit-autofill animation are a hack to detect that the input have been autofill by Google Chrome
   * It attach some void animations to allow the autofill detection in JS.
   *
   * @see https://medium.com/@brunn/detecting-autofilled-fields-in-javascript-aed598d25da7
   */
  &:-webkit-autofill {
    animation-name: ${onAutoFillStart};
  }
  &:not(:-webkit-autofill) {
    animation-name: ${onAutoFillCancel};
  }
`

export const CurrencyInput = styled(FormattedInputBase).attrs({
  thousandSeparator: ' ',
  decimalScale: 0,
  allowNegative: false,
  placeholder: ' €',
  suffix: ' €',
  customInput: Input,
})`
  font-family: ${RobotoBold.fontFamily};
  font-size: 22px;
  font-weight: ${RobotoBold.fontWeight};
`
