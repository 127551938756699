// @flow

import styled from 'styled-components'
import { Colors } from './../../Colors'
import { RobotoMedium, RobotoBold } from './../../Typography/Fonts'
import { Button } from './../Button'
import { Input, CurrencyInput } from './Input'
import FloatingLabel from './FloatingLabel'
import Fieldset from './Fieldset'
import RadioInput, { RadioInputList } from './RadioInput'

export {
  Input,
  FloatingLabel,
  CurrencyInput,
  Fieldset,
  RadioInput,
  RadioInputList,
}

export const LabelInput = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-weight: ${RobotoBold.fontWeight};
  position: absolute;
  font-size: 10px;
  top: 5px;
  left: 12px;
  padding: 0 4px;
  line-height: 1;
  z-index: 1;
  color: ${Colors.greyRegular};
  ${Input}:focus & {
    color: ${Colors.green};
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    right: 0;
    top: 5px;
    z-index: -1;
    background-color: ${Colors.white};
  }
`

export const FieldError = styled.p`
  margin-top: 15px;
  margin-bottom: 10px;
  color: #ef4872;
`

export const SubmitButton = styled(Button).attrs({ type: 'submit' })`
  margin-top: 10px;
`

export const FormDescription = styled.p`
  margin: 0;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 20px;
  color: ${Colors.greyRegular};
`

export const Form = styled.form`
  & > ${Input}:nth-of-type(1) {
    margin-top: 0;
  }
`
