// @flow

import * as React from 'react'
import styled from 'styled-components'
import {
  Form,
  Input,
  FloatingLabel,
  SubmitButton,
  FieldError,
} from './../../Guidelines'
import AuthenticationStore from '../../Stores/AuthenticationStore'

const FormWrapper = styled.div`
  width: 290px;
  margin: 0;
`

type Props = {|
  authenticationStore: AuthenticationStore,
|}

type State = {|
  email: string,
  password: string,
  errors: Array<{ field: ?string, message: string }>,
|}

class LoginForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      email:
        process.env.NODE_ENV === 'development' ? 'seller@custhome.app' : '',
      password: process.env.NODE_ENV === 'development' ? '12345678' : '',
      errors: [],
    }
  }

  onLogin = () => {
    const { authenticationStore } = this.props
    const { email, password } = this.state

    authenticationStore
      .performLogin(email, password)
      .then(({ success, errors }) => {
        if (!success) {
          this.setState({ errors })
        } else {
          this.setState({ errors: [] })
        }
      })
  }

  render() {
    const { email, password, errors } = this.state

    return (
      <FormWrapper>
        <Form
          onSubmit={e => {
            e.preventDefault()
            this.onLogin()
          }}
        >
          <FloatingLabel label="Login">
            <Input
              id="email_input_login"
              type="text"
              value={email}
              onChange={e => {
                this.setState({ email: e.target.value })
              }}
              placeholder="Email"
              errored={errors.length > 0}
            />
          </FloatingLabel>

          <FloatingLabel label="Mot de passe">
            <Input
              id="password_input_login"
              type="password"
              value={password}
              onChange={e => {
                this.setState({ password: e.target.value })
              }}
              placeholder="Mot de passe"
              errored={errors.length > 0}
            />
          </FloatingLabel>

          {errors.map(({ message }) => (
            <FieldError key={message}>{message}</FieldError>
          ))}

          <SubmitButton value="Connexion" />
        </Form>
      </FormWrapper>
    )
  }
}

export default LoginForm
