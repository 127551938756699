// @flow

import React from 'react'
import ProgramParameterSwitch from './ProgramParameterSwitch'

const ProgramVatParameterSwitch = ({
  vat,
  disabled = false,
  onChange,
}: {
  vat: 5.5 | 20,
  disabled?: boolean,
  onChange: (newVat: 5.5 | 20) => void,
}) => (
  <ProgramParameterSwitch
    description="Quel taux de TVA doit être affiché sur les prix de la maquette en ligne ?"
    onLabel="TVA 20 %"
    offLabel="TVA 5,5 %"
    toggled={vat !== 5.5}
    disabled={disabled}
    onSwitch={toggled => onChange(toggled ? 20 : 5.5)}
  />
)

export default ProgramVatParameterSwitch
