// @flow

import { computed, observable } from 'mobx'
import Program from './Program'
import CommercialOffer, { type LotLoan, type LotVat } from './CommercialOffer'
import CustomerSummary from './CustomerSummary'

export type LotStatus = 'available' | 'optioned' | 'booked' | 'sold'

export default class Lot {
  +lotId: string
  +label: string
  +orientation: string
  +area: number
  +balconyArea: ?number
  +gardenArea: ?number
  +typology: string
  +floor: number
  +buildingId: string
  +planUrl: string
  +program: Program
  +duplex: boolean

  @observable status: LotStatus = 'available'
  @observable statusChangedAt: Date = new Date()
  @observable priceIncludingTaxes20: number = 0
  @observable priceIncludingTaxes55: number = 0
  @observable commercialOffer: ?CommercialOffer = null

  constructor(
    lotId: string,
    status: LotStatus,
    statusChangedAt: Date,
    label: string,
    priceIncludingTaxes20: number,
    priceIncludingTaxes55: number,
    orientation: string,
    area: number,
    balconyArea: ?number,
    gardenArea: ?number,
    typology: string,
    floor: number,
    buildingId: string,
    planUrl: string,
    commercialOffer: ?CommercialOffer,
    program: Program,
    duplex: boolean,
  ) {
    this.lotId = lotId
    this.status = status
    this.statusChangedAt = statusChangedAt
    this.label = label
    this.priceIncludingTaxes20 = priceIncludingTaxes20
    this.priceIncludingTaxes55 = priceIncludingTaxes55
    this.orientation = orientation
    this.area = area
    this.balconyArea = balconyArea
    this.gardenArea = gardenArea
    this.typology = typology
    this.floor = floor
    this.buildingId = buildingId
    this.planUrl = planUrl
    this.commercialOffer = commercialOffer
    this.program = program
    this.duplex = duplex
  }

  is(lookupId: string): boolean {
    return this.lotId === lookupId
  }

  _updateChangedAt(): void {
    const now = new Date()
    this.statusChangedAt = now
  }

  updateStatus(status: LotStatus): void {
    this.status = status
    this._updateChangedAt()

    if (status === 'available' && this.commercialOffer) {
      this.commercialOffer = null
    }
  }

  updateConfiguration(
    configurationId: string,
    configurationOptionPrice: number,
    receiptUrl: string,
  ): void {
    if (!this.commercialOffer) {
      throw new Error('No customer offer instantiated')
    }
    this.commercialOffer.updateConfiguration(
      configurationId,
      configurationOptionPrice,
      receiptUrl,
    )
  }

  createCommercialOffer(
    priceIncludingTaxes: number,
    vat: LotVat,
    loan: LotLoan,
    customerId: ?string,
    firstName: ?string,
    lastName: ?string,
  ) {
    this.commercialOffer = new CommercialOffer(
      vat,
      loan,
      customerId && firstName && lastName
        ? new CustomerSummary(customerId, firstName, lastName)
        : null,
      priceIncludingTaxes,
      null,
      priceIncludingTaxes,
    )
  }

  updateFinancialInformations(
    vat: LotVat,
    loan: LotLoan,
    lotPriceIncludingVAT: number,
    totalPriceIncludingVAT: number,
  ): void {
    if (!this.commercialOffer) {
      throw new Error('No customer offer instantiated')
    }
    this.commercialOffer.updateFinancialInformations(
      vat,
      loan,
      lotPriceIncludingVAT,
      totalPriceIncludingVAT,
    )
  }

  updateCustomer(firstName: string, lastName: string): void {
    if (!this.commercialOffer) {
      throw new Error('No customer offer instantiated')
    }
    this.commercialOffer.updateCustomer(firstName, lastName)
  }

  updatePrice(
    priceIncludingTaxes20: number,
    priceIncludingTaxes55: number,
  ): void {
    this.priceIncludingTaxes20 = priceIncludingTaxes20
    this.priceIncludingTaxes55 = priceIncludingTaxes55
  }

  @computed get previewUrl(): string {
    if (
      this.program.isBusinessPack() &&
      this.commercialOffer &&
      this.commercialOffer.configurationId
    ) {
      return `${this.program.programPreviewUrl}/lot/${this.lotId}?configurationId=${this.commercialOffer.configurationId}&referrer=from_seller_area`
    } else if (this.program.isBusinessPack()) {
      return `${this.program.programPreviewUrl}/lot/${this.lotId}?referrer=from_seller_area`
    }

    return this.planUrl
  }

  @computed get visiblePriceWithVat20(): number {
    return this.commercialOffer
      ? this.commercialOffer.totalPriceIncludingVAT
      : this.priceIncludingTaxes20
  }

  @computed get visiblePriceWithVat5(): number {
    return this.commercialOffer
      ? this.commercialOffer.totalPriceIncludingVAT
      : this.priceIncludingTaxes55
  }

  @computed get haveCommercialOffertAssociatied(): boolean {
    return !!this.commercialOffer
  }

  @computed get commercialOfferPrice(): number {
    if (!this.commercialOffer) {
      throw new Error('No commercial offer assiociated')
    }

    return this.commercialOffer.lotPriceIncludingVAT
  }

  @computed get commercialOfferVat(): LotVat {
    if (!this.commercialOffer) {
      throw new Error('No commercial offer assiociated')
    }

    return this.commercialOffer.vat
  }

  @computed get commercialOfferLoan() {
    if (!this.commercialOffer) {
      throw new Error('No commercial offer assiociated')
    }

    return this.commercialOffer.loan
  }

  @computed get commercialOfferCustomerName(): ?{
    firstName: string,
    lastName: string,
  } {
    return this.commercialOffer ? this.commercialOffer.customerName : null
  }

  @computed get commercialOfferUpdateApi() {
    if (this.commercialOffer) {
      return {
        priceIncludingTaxes: this.commercialOffer.lotPriceIncludingVAT,
        vat: this.commercialOffer.vat,
        loan: this.commercialOffer.loan,
        customerId: this.commercialOffer.customerId,
      }
    }
    return null
  }

  @computed get haveEditableCommercialOffert(): boolean {
    return this.status === 'optioned' || this.status === 'booked'
  }

  @computed get faded(): boolean {
    return this.status === 'unavailable'
  }
}
