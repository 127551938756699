// @flow

import * as React from 'react'
import styled from 'styled-components'
import { RobotoNormal } from '../../Typography/Fonts'
import { Colors } from '../../Colors'

const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 32px;

  font-size: 24px;
  line-height: 1;
  font-family: ${RobotoNormal.fontFamily};
  font-weight: ${RobotoNormal.fontWeight};
`

const BrandContainer = styled.div`
  width: 140px;
  height: 32px;
  padding-top: 1px;
  pointer-events: ${({ active }) => (active ? 'none' : 'initial')};
  path {
    transition: fill 0.25s ease-in-out;
    fill: ${({ active }) =>
      active ? Colors.blackUltraBold : Colors.greyRegular};
  }
  &:hover {
    path {
      fill: ${Colors.green};
    }
  }
`

const BreadcrumbSeparator = styled.span`
  color: ${Colors.greyRegular};
`

const BreadcrumbItemLabel = styled.span`
  pointer-events: ${({ active }) => (active ? 'none' : 'default')};

  & a,
  & a:focus,
  & a:visited {
    color: gold;
    text-decoration: none;
    transition: color 0.25s ease-in-out;

    color: ${({ active }) =>
      active ? Colors.blackUltraBold : Colors.greyRegular};
  }

  & a:hover {
    color: ${Colors.green};
  }
`

const BreadcrumbItemButton = styled.span`
  margin-left: 10px;
`

export const BrandedBreadcrumb = ({
  separator = '›',
  callToAction,
  children,
}: {|
  separator?: string,
  callToAction?: React.Node,
  children: React.Node,
|}) => {
  const nbItems = React.Children.toArray(children).filter(c => c).length

  return (
    <BreadcrumbContainer>
      {React.Children.map(children, (oneChildren, i) => {
        if (!oneChildren) {
          return null
        }

        const isFirstChild = i === 0
        const isLastChild = i === nbItems - 1

        if (isFirstChild) {
          return (
            <BrandContainer active={isLastChild}>{oneChildren}</BrandContainer>
          )
        }

        return (
          <React.Fragment>
            <BreadcrumbSeparator>
              {'\u00A0'}
              {separator}
              {'\u00A0'}
            </BreadcrumbSeparator>
            <BreadcrumbItemLabel active={isLastChild}>
              {oneChildren}
            </BreadcrumbItemLabel>
          </React.Fragment>
        )
      })}

      {callToAction ? (
        <BreadcrumbItemButton>{callToAction}</BreadcrumbItemButton>
      ) : null}
    </BreadcrumbContainer>
  )
}

export const BrandedBreadcrumbItem = ({
  children,
}: {|
  children: React.Node,
|}) => children
