// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <circle id="balcony__a" cx={4.5} cy={4.5} r={2.5} />
      <filter
        id="balcony__b"
        width="140%"
        height="140%"
        x="-20%"
        y="-20%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.93573877 0 0 0 0 0.971580616 0 0 0 0 0.958305858 0 0 0 1 0"
        />
      </filter>
      <circle id="balcony__c" cx={12} cy={4} r={2} />
      <filter
        id="balcony__d"
        width="150%"
        height="150%"
        x="-25%"
        y="-25%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.93573877 0 0 0 0 0.971580616 0 0 0 0 0.958305858 0 0 0 1 0"
        />
      </filter>
      <circle id="balcony__e" cx={8} cy={3} r={3} />
      <filter
        id="balcony__f"
        width="133.3%"
        height="133.3%"
        x="-16.7%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.93573877 0 0 0 0 0.971580616 0 0 0 0 0.958305858 0 0 0 1 0"
        />
      </filter>
      <path
        id="balcony__g"
        d="M2.151 8H13.85C15.037 8 16 8.895 16 10c0 .164-.022.326-.064.485l-1.076 4c-.24.89-1.1 1.515-2.086 1.515H3.227c-.987 0-1.847-.625-2.087-1.515l-1.075-4C-.223 9.413.477 8.328 1.63 8.06c.17-.04.346-.06.521-.06z"
      />
      <filter
        id="balcony__h"
        width="112.5%"
        height="125%"
        x="-6.2%"
        y="-12.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.93573877 0 0 0 0 0.971580616 0 0 0 0 0.958305858 0 0 0 1 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#62CFA8"
        fillRule="nonzero"
        d="M4.5 5a.5.5 0 0 1 .5.5v7a.5.5 0 1 1-1 0v-7a.5.5 0 0 1 .5-.5zm0 1a.5.5 0 0 0 .5-.5v7a.5.5 0 1 0-1 0v-7a.5.5 0 0 0 .5.5zM12 4a.5.5 0 0 1 .5.5v7a.5.5 0 1 1-1 0v-7A.5.5 0 0 1 12 4zm0 1a.5.5 0 0 0 .5-.5v7a.5.5 0 1 0-1 0v-7a.5.5 0 0 0 .5.5zM8 3a.5.5 0 0 1 .5.5v7a.5.5 0 1 1-1 0v-7A.5.5 0 0 1 8 3zm0 1a.5.5 0 0 0 .5-.5v7a.5.5 0 1 0-1 0v-7A.5.5 0 0 0 8 4z"
      />
      <use fill="#D8F3E9" xlinkHref="#balcony__a" />
      <use fill="#000" filter="url(#balcony__b)" xlinkHref="#balcony__a" />
      <circle
        cx={4.5}
        cy={4.5}
        r={2}
        stroke="#62CFA8"
        strokeLinejoin="square"
      />
      <use fill="#D8F3E9" xlinkHref="#balcony__c" />
      <use fill="#000" filter="url(#balcony__d)" xlinkHref="#balcony__c" />
      <circle cx={12} cy={4} r={1.5} stroke="#62CFA8" strokeLinejoin="square" />
      <g>
        <use fill="#D8F3E9" xlinkHref="#balcony__e" />
        <use fill="#000" filter="url(#balcony__f)" xlinkHref="#balcony__e" />
        <circle
          cx={8}
          cy={3}
          r={2.5}
          stroke="#62CFA8"
          strokeLinejoin="square"
        />
      </g>
      <g>
        <use fill="#D8F3E9" xlinkHref="#balcony__g" />
        <use fill="#000" filter="url(#balcony__h)" xlinkHref="#balcony__g" />
        <path
          stroke="#62CFA8"
          strokeLinejoin="square"
          d="M2.151 8.5c-.137 0-.275.016-.408.047C.859 8.752.335 9.564.548 10.355l1.075 4c.18.667.838 1.145 1.604 1.145h9.547c.766 0 1.424-.478 1.603-1.145l1.076-4c.031-.116.047-.235.047-.355 0-.82-.731-1.5-1.65-1.5H2.15z"
        />
      </g>
    </g>
  </svg>
)
