// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../Colors'
import { RobotoBold } from '../Typography/Fonts'

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 30px;
`

const TabContainer = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 22px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 1.36;
  margin: 0 15px;
  cursor: pointer;
  pointer-events: ${({ active }) => (active ? 'none' : 'initial')};
  transition: all 0.25s ease-in-out;
  border-bottom: 2px solid
    ${({ active }) => (active ? Colors.green : 'transparent')};
  color: ${({ active }) =>
    active ? Colors.blackUltraBold : Colors.greyRegular};
`

export const Tabs = ({ children }: { children: React.Node }) => {
  const [activeTab, setActiveTab] = React.useState(
    React.Children.toArray(children)[0].props.id,
  )
  const tabs = React.Children.toArray(children).filter(child => !!child)

  return (
    <React.Fragment>
      <TabsWrapper>
        {tabs.map((oneTab, i) => {
          return (
            <TabContainer
              key={i}
              active={activeTab === oneTab.props.id}
              onClick={() => setActiveTab(oneTab.props.id)}
            >
              {oneTab.props.title}
            </TabContainer>
          )
        })}
      </TabsWrapper>
      {tabs.map((oneTab, i) => {
        if (activeTab === oneTab.props.id) {
          return oneTab.props.children
        } else {
          return null
        }
      })}
    </React.Fragment>
  )
}

export const Tab = ({
  id,
  children,
  active,
  title,
}: {
  id: string,
  title: string,
  children: React.Node,
  active?: boolean,
}) => <TabContainer active={active}>{title}</TabContainer>
