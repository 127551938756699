// @flow
import * as React from 'react'
import styled from 'styled-components'
import nanoid from 'nanoid'
import { onAutoFillCancel, onAutoFillStart } from './Input'
import { Colors } from '../../Colors'
import { RobotoBold } from '../../Typography/Fonts'

const LabelWrapper = styled.div`
  position: relative;
  background: transparent;

  & input::placeholder {
    color: ${({ linkedInputIsFocused, linkedInputIsEmpty }) =>
      !linkedInputIsFocused || (linkedInputIsFocused && !linkedInputIsEmpty)
        ? 'transparent'
        : Colors.greyRegular};

    transition: color ease-in-out 200ms;
  }
`

const LabelIntput = styled.label`
  position: absolute;
  top: ${({ floating }) => (floating ? '5px' : '30px')};
  left: ${({ floating }) => (floating ? '12px' : '16px')};
  padding: ${({ floating }) => (floating ? '0 4px' : '0px 0px')};
  font-family: ${RobotoBold.fontFamily};
  font-weight: ${RobotoBold.fontWeight};
  font-size: ${({ floating }) => (floating ? '10px' : '17px')};
  color: ${Colors.greyRegular};
  z-index: 1;
  transition: ${({ animateTransition }) =>
    animateTransition ? 'none' : 'all ease-in-out 200ms'};

  color: ${({ linkedInputIsFocused, linkedInputIsErrored }) =>
    linkedInputIsFocused
      ? Colors.green
      : linkedInputIsErrored
      ? Colors.pinkBold
      : Colors.greyRegular};

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    right: 0;
    top: 5px;
    z-index: -1;
    background-color: ${Colors.white};
  }
`

export default ({
  label,
  children,
  ...props
}: {
  label: string,
  children: React.Node,
}) => {
  const originalInput = React.Children.only(children)

  const {
    id,
    placeholder,
    value,
    defaultValue,
    errored = false,
    onChange,
    onFocus,
    onBlur,
    onAnimationStart,
  } = originalInput.props

  const [inputId] = React.useState(id || `input-${nanoid()}`)
  const [inputIsFocused, setInputIsFocused] = React.useState(false)
  const [
    inputIsAutofillByGoogleChrome,
    setInputIsAutofillByGoogleChrome,
  ] = React.useState(false)
  const [inputIsEmpty, setInputIsEmpty] = React.useState(
    !value && !defaultValue,
  )

  React.useEffect(() => {
    setInputIsEmpty(!value && !defaultValue)
  }, [value, defaultValue])

  const inputElement = React.cloneElement(originalInput, {
    id: inputId,

    ...(placeholder ? { placeholder: ' ' } : null),

    onChange: (e, ...args) => {
      setInputIsEmpty(!e.target.value)

      if (onChange) {
        onChange(e, ...args)
      }
    },

    onFocus: (e, ...args) => {
      setInputIsFocused(true)

      if (onFocus) {
        onFocus(e, ...args)
      }
    },

    onBlur: (e, ...args) => {
      setInputIsFocused(false)

      if (onBlur) {
        onBlur(e, ...args)
      }
    },

    // This handler use empty CSS anination to detect if Google Chrome autofill the value in the input
    onAnimationStart: (e, ...args) => {
      if (e.animationName === onAutoFillStart.name) {
        setInputIsAutofillByGoogleChrome(true)
      }

      if (e.animationName === onAutoFillCancel.name) {
        setInputIsAutofillByGoogleChrome(false)
      }

      if (onAnimationStart) {
        onAnimationStart(e, ...args)
      }
    },
  })

  return (
    <LabelWrapper
      linkedInputIsEmpty={inputIsEmpty}
      linkedInputIsFocused={inputIsFocused}
    >
      <LabelIntput
        htmlFor={inputId}
        floating={
          inputIsAutofillByGoogleChrome || inputIsFocused || !inputIsEmpty
        }
        animateTransition={defaultValue || value}
        linkedInputIsFocused={inputIsFocused}
        linkedInputIsErrored={errored}
      >
        {label}
      </LabelIntput>

      {inputElement}
    </LabelWrapper>
  )
}
