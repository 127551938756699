// @flow

import { action, observable, runInAction } from 'mobx'
import { RootStore } from '../RootStore'
import Program from '../Domain/Program'
import Building from '../Domain/Building'

export default class ProgramsStore {
  +rootStore: RootStore

  @observable programs: Program[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action async fetchPrograms() {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      '/api/seller/programs',
    )

    runInAction(() => {
      this.programs = response.data.map(
        oneProgramPayload =>
          new Program(
            oneProgramPayload.programId,
            oneProgramPayload.label,
            oneProgramPayload.location,
            oneProgramPayload.totalNumberOfLot,
            oneProgramPayload.pack,
            oneProgramPayload.domain,
            oneProgramPayload.buildings.map(
              oneBuildingPayload =>
                new Building(
                  oneBuildingPayload.buildingId,
                  oneBuildingPayload.label,
                ),
            ),
            oneProgramPayload.vat,
            oneProgramPayload.parkingIncluded,
          ),
      )
    })
  }

  getProgram(programId: string): Program {
    const program = this.programs.find(
      oneProgram => oneProgram.id === programId,
    )
    if (!program) {
      throw new Error(`No program found with the id "${programId}"`)
    }

    return program
  }
}
