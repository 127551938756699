// @flow

import * as React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { Link, FakeLink } from '../../Guidelines'
import ApplicationLayout from '../../ApplicationLayout'
import ProgramsStore from '../../Stores/ProgramsStore'
import routes from '../../Routes'
import RouterStore from '../../Stores/RouterStore'
import AuthenticationStore from '../../Stores/AuthenticationStore'
import LotPricesBulkEditionStore from './LotPricesBulkEditionStore'
import {
  ColomnsLayout,
  Colomn,
  Separator,
} from '../../Guidelines/components/ThreeColumnsLayout'
import LotStatusLegend from './LotStatusLegend'
import LotPricesBulkEditionForm from './LotPricesBulkEditionForm'
import BuildingSelector from './BuildingSelector'
import ProgramVatParameterSwitch from './ProgramVatParameterSwitch'
import ProgramPriceIncludeParkingLotSwitch from './ProgramPriceIncludeParkingLotSwitch'

const BulkEditionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
`

const LotPricesBulkEditionPage = inject(
  'lotPricesBulkEditionStore',
  'programsStore',
  'routerStore',
  'authenticationStore',
)(
  observer(
    ({
      lotPricesBulkEditionStore,
      programsStore,
      routerStore,
      authenticationStore,
    }: {
      lotPricesBulkEditionStore: LotPricesBulkEditionStore,
      programsStore: ProgramsStore,
      routerStore: RouterStore,
      authenticationStore: AuthenticationStore,
    }) => {
      const program = lotPricesBulkEditionStore.programId
        ? programsStore.getProgram(lotPricesBulkEditionStore.programId)
        : null

      if (!program) {
        return <ApplicationLayout breadcrumb={[]} />
      }

      return (
        <ApplicationLayout
          breadcrumb={[
            <Link
              key={`${program.id}-lots`}
              view={routes.lots_management_page}
              params={{ programId: program.id }}
              router={routerStore}
            >
              {program.label}
            </Link>,
            <Link
              key={`${program.id}-lot-prices`}
              view={routes.lots_management_page}
              params={{ programId: program.id }}
              router={routerStore}
            >
              Paramètres de l’opération
            </Link>,
          ]}
        >
          <BulkEditionWrapper>
            <ColomnsLayout>
              <Colomn>
                <ProgramPriceIncludeParkingLotSwitch
                  isPriceIncludeParkingLot={
                    lotPricesBulkEditionStore.programPriceIncludeParkingLot
                  }
                  disabled={lotPricesBulkEditionStore.programVatIsSubmitted}
                  onChange={includeParkingLot => {
                    lotPricesBulkEditionStore.saveProgramPriceIncludeParkingLot(
                      includeParkingLot,
                    )
                  }}
                />

                <Separator />

                <ProgramVatParameterSwitch
                  vat={lotPricesBulkEditionStore.programVat}
                  disabled={lotPricesBulkEditionStore.programVatIsSubmitted}
                  onChange={newVat => {
                    lotPricesBulkEditionStore.saveProgramVat(newVat)
                  }}
                />

                <Separator />

                <div style={{ textAlign: 'right' }}>
                  <FakeLink
                    accented
                    onClick={() => window.open(program.programPreviewUrl)}
                  >
                    Voir l’opération en ligne
                  </FakeLink>
                </div>
              </Colomn>

              <Colomn large>
                <LotPricesBulkEditionForm
                  lots={lotPricesBulkEditionStore.lots}
                  selectedBuildingId={
                    lotPricesBulkEditionStore.selectedBuildingId
                  }
                  onLotPriceIncludingTaxes20Changed={(
                    lotId,
                    newPriceIncludingTaxes20,
                  ) =>
                    lotPricesBulkEditionStore.changeLotPriceIncludingTaxes20(
                      lotId,
                      newPriceIncludingTaxes20,
                    )
                  }
                  onLotPriceIncludingTaxes55Changed={(
                    lotId,
                    newPriceIncludingTaxes55,
                  ) =>
                    lotPricesBulkEditionStore.changeLotPriceIncludingTaxes55(
                      lotId,
                      newPriceIncludingTaxes55,
                    )
                  }
                  onSubmit={() => lotPricesBulkEditionStore.saveNewLotPrices()}
                />
              </Colomn>

              <Colomn>
                <BuildingSelector
                  buildings={program.getBuildings().map(oneBuilding => ({
                    id: oneBuilding.id,
                    label: oneBuilding.label,
                  }))}
                  selectedBuildingId={
                    lotPricesBulkEditionStore.selectedBuildingId
                  }
                  onChange={newSelectedBuildingId =>
                    lotPricesBulkEditionStore.filterVisiblePriceByBuildingId(
                      newSelectedBuildingId,
                    )
                  }
                />

                <Separator />

                <LotStatusLegend />
              </Colomn>
            </ColomnsLayout>
          </BulkEditionWrapper>
        </ApplicationLayout>
      )
    },
  ),
)

export default LotPricesBulkEditionPage
