// @flow

import Building from './Building'
import { observable } from 'mobx'

const PACK = {
  STANDARD: 'maquette3d',
  PREMIUM: 'maquette3d+visite_virtuelle',
  BUSINESS: 'maquette3d+configurateur',
}

export default class Program {
  +id: string
  +label: string
  +location: string
  +totalNumberOfLot: number
  +pack: string
  +programPreviewUrl: string
  +buildings: Building[]

  @observable vat: 5.5 | 20 = 20
  @observable priceIncludeParkingLot: boolean = false

  constructor(
    id: string,
    label: string,
    location: string,
    totalNumberOfLot: number,
    pack: string,
    programPreviewUrl: string,
    buildings: Building[],
    vat: 5.5 | 20,
    priceIncludeParkingLot: boolean,
  ) {
    this.id = id
    this.label = label
    this.location = location
    this.totalNumberOfLot = totalNumberOfLot
    this.pack = pack

    if (
      !programPreviewUrl.includes('https://') &&
      !programPreviewUrl.includes('http://')
    ) {
      this.programPreviewUrl = `https://${programPreviewUrl}`
    } else {
      this.programPreviewUrl = programPreviewUrl
    }

    if (buildings.length === 0) {
      throw new Error('')
    }
    this.buildings = buildings

    if (vat !== 5.5 && vat !== 20) {
      throw new Error(`Invalid program vat "${vat}. Should be "5.5" or "20"."`)
    }
    this.vat = vat

    this.priceIncludeParkingLot = priceIncludeParkingLot
  }

  isStandardPack(): boolean {
    return this.pack === PACK.STANDARD
  }

  isPremiumPack(): boolean {
    return this.pack === PACK.PREMIUM
  }

  isBusinessPack(): boolean {
    return this.pack === PACK.BUSINESS
  }

  getFirstBuilding(): Building {
    return this.buildings[0]
  }

  getBuildings(): Building[] {
    return this.buildings
  }

  haveABuildingWithId(buildingId: string): boolean {
    const building = this.buildings.find(oneBuilding =>
      oneBuilding.is(buildingId),
    )

    return !!building
  }

  changeVat(newVat: 5.5 | 20): void {
    this.vat = newVat
  }

  changePriceIncludeParkingPrice(newPrinceIncludeParkingPrice: boolean): void {
    this.priceIncludeParkingLot = newPrinceIncludeParkingPrice
  }
}
