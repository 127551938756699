// @flow

import React, { useState } from 'react'
import styled from 'styled-components'
import {
  RadioInput,
  RadioInputList,
  CurrencyInput,
  Form,
  SubmitButton,
  Fieldset,
} from '../Form'
import PenSvg from '../../icons/PenSvg'
import { type LotStatus } from '../../../Domain/Lot'
import Taxes from '../../Typography/Taxes'

const Container = styled.div`
  width: 310px;
`

const PublicPricesLayout = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;

  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`

const CommercialOffertPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > svg {
    flex: none;
    margin-left: 16px;
  }
`

const ReadOnlyCurrencyInput = styled(CurrencyInput).attrs({ disabled: true })`
  border: none;
  margin-top: 0;
  text-align: center;
`

const CurrencyInputCustom = styled(CurrencyInput)`
  border: none;
  margin-top: 0;
  border: none;

  &:focus {
    border: none;
  }
`

const RadioTvaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: min-content;
  padding: 0 16px 0 0;

  & > * {
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const RadioLoanContainer = styled(RadioInputList)`
  margin: 35px 0 25px 0;
`

type VatType = 20 | 5.5
type LoanType = 'no_action_initiated' | 'loan_simulation_done' | 'loan_granted'

export default ({
  programVat,
  priceIncludingTaxes55,
  priceIncludingTaxes20,
  commercialOfferPrice,
  commercialOfferVat,
  commercialOfferLoan,
  status,
  labelButton,
  onSubmit,
}: {
  programVat: VatType,
  priceIncludingTaxes55: number,
  priceIncludingTaxes20: number,
  commercialOfferPrice: ?number,
  commercialOfferVat: ?VatType,
  commercialOfferLoan: ?LoanType,
  status: LotStatus,
  labelButton: string,
  onSubmit: (
    priceIncludingTaxes: number,
    vat: VatType,
    loan: LoanType,
    status: LotStatus,
  ) => void,
}) => {
  const [price, setPrice] = useState(() => {
    if (commercialOfferPrice) {
      return commercialOfferPrice
    }

    if (programVat === 5.5) {
      return priceIncludingTaxes55
    } else {
      return priceIncludingTaxes20
    }
  })
  const [vat, setVat] = useState(commercialOfferVat || programVat)
  const [loan, setLoan] = useState(commercialOfferLoan || 'no_action_initiated')

  const handleVatChange = (newVat: VatType) => setVat(newVat)
  const handleLoanChange = (newLoan: LoanType) => setLoan(newLoan)

  return (
    <Container>
      <Form
        onSubmit={e => {
          e.preventDefault()
          onSubmit(price, vat, loan, status)
        }}
      >
        <PublicPricesLayout>
          <Fieldset
            legend={
              <React.Fragment>
                Prix public | <Taxes amount={20} />
              </React.Fragment>
            }
            alignContent="center"
          >
            <ReadOnlyCurrencyInput
              thousandSeparator=" "
              precision="0"
              suffix=" €"
              value={priceIncludingTaxes20}
            />
          </Fieldset>
          <Fieldset
            legend={
              <React.Fragment>
                Prix public | <Taxes amount={5.5} />
              </React.Fragment>
            }
            alignContent="center"
          >
            <ReadOnlyCurrencyInput
              thousandSeparator=" "
              precision="0"
              suffix=" €"
              value={priceIncludingTaxes55}
            />
          </Fieldset>
        </PublicPricesLayout>

        <Fieldset legend="Prix de vente">
          <CommercialOffertPriceContainer>
            <PenSvg />

            <div>
              <CurrencyInputCustom
                thousandSeparator=" "
                precision="0"
                suffix=" €"
                value={price}
                onValueChange={({ floatValue }) => {
                  setPrice(floatValue)
                }}
              />
            </div>

            <RadioTvaContainer>
              <RadioInput
                checked={vat === 20}
                onChange={handleVatChange}
                value={20}
                name="vat"
                label={<Taxes amount={20} />}
              />
              <RadioInput
                checked={vat === 5.5}
                onChange={handleVatChange}
                value={5.5}
                name="vat"
                label={<Taxes amount={5.5} />}
              />
            </RadioTvaContainer>
          </CommercialOffertPriceContainer>
        </Fieldset>

        <RadioLoanContainer>
          <RadioInput
            name="loan"
            value="no_action_initiated"
            label="Aucune démarche initiée"
            checked={loan === 'no_action_initiated'}
            onChange={handleLoanChange}
          />
          <RadioInput
            name="loan"
            value="loan_simulation_done"
            label="Simulation de prêt effectuée"
            checked={loan === 'loan_simulation_done'}
            onChange={handleLoanChange}
          />
          <RadioInput
            name="loan"
            value="loan_granted"
            label="Offre de prêt accordée"
            checked={loan === 'loan_granted'}
            onChange={handleLoanChange}
          />
        </RadioLoanContainer>

        <SubmitButton value={labelButton} />
      </Form>
    </Container>
  )
}
