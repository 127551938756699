// @flow

import React from 'react'
import styled from 'styled-components'
import LotStatusDot from './LotStatusDot'
import { Colors } from '../../Guidelines'

const List = styled.ul`
  padding: 0;
`

const ListItem = styled.li`
  margin-bottom: 10px;
  list-style: none;
  color: ${Colors.greyBlue};
  font-size: 14px;
  line-height: 1.43;
`

const Label = styled.span`
  display: inline-block;
  text-indent: 4px;
`

const LotStatusLegend = () => (
  <List>
    <ListItem>
      <LotStatusDot value="available" />
      <Label>Disponible & Indisponible</Label>
    </ListItem>
    <ListItem>
      <LotStatusDot value="booked" />
      <Label>Optionné & Réservé</Label>
    </ListItem>
    <ListItem>
      <LotStatusDot value="sold" />
      <Label>Vendu</Label>
    </ListItem>
  </List>
)

export default LotStatusLegend
