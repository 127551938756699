// @flow

export const PossibleOrientations = [
  { key: 'N', label: 'Nord' },
  { key: 'NE', label: 'Nord-Est' },
  { key: 'E', label: 'Est' },
  { key: 'SE', label: 'Sud-Est' },
  { key: 'S', label: 'Sud' },
  { key: 'SO', label: 'Sud-Ouest' },
  { key: 'O', label: 'Ouest' },
  { key: 'NO', label: 'Nord-Ouest' },
]

export const orientationLabel = (orientation: string): string => {
  switch (orientation) {
    case 'N':
      return 'Nord'

    case 'NE':
      return 'Nord-Est'

    case 'NO':
      return 'Nord-Ouest'

    case 'E':
      return 'Est'

    case 'S':
      return 'Sud'

    case 'SE':
      return 'Sud-Est'

    case 'SO':
      return 'Sud-Ouest'

    case 'O':
      return 'Ouest'

    default:
      throw new Error(`No orientation label for orientation "${orientation}"`)
  }
}
