// @flow
import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../../Colors'
import { RobotoMedium } from '../../Typography/Fonts'

const SwitchWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  justify-content: ${({ align }) =>
    align === 'start' ? 'flex-start' : align === 'end' ? 'flex-end' : 'center'};
  transition: opacity 250ms ease-in-out;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

const SwitchLabel = styled.div`
  z-index: 9;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 1.43;
  user-select: none;
  cursor: pointer;
  transition: color 0.25s ease-in-out;

  ${props =>
    props.selected
      ? `color: ${Colors.greyRegular}`
      : `color: ${Colors.blackUltraBold}`};

  &:hover {
    color: ${Colors.blackUltraBold};
  }
`

const SwitchToggle = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${Colors.green};
  border-radius: 50%;
  transition: transform 0.25s ease-in-out;
  position: absolute;
  left: 0;
`

const SwitchButton = styled.div.attrs({
  role: 'button',
})`
  cursor: pointer;
  padding: 3px;
  width: 34px;
  height: 20px;
  position: relative;
  margin: 0 10px;
  border-radius: 10px;
  border: solid 1px #dddddd;

  ${SwitchToggle} {
    transform: ${props =>
      props.toggled ? 'translateX(3px)' : 'translateX(17px)'};
  }
`

type SwitchProps = {
  onLabel: React.Node,
  offLabel: React.Node,
  onChange: () => void,
  toggled: boolean,
  disabled?: boolean,
  align?: 'start' | 'center' | 'end',
}

export default ({
  onLabel,
  offLabel,
  onChange,
  toggled,
  disabled = false,
  align = 'center',
}: SwitchProps) => (
  <SwitchWrapper align={align} className={disabled ? 'disabled' : null}>
    <SwitchLabel selected={!toggled} onClick={onChange}>
      {onLabel}
    </SwitchLabel>
    <SwitchButton toggled={toggled} onClick={onChange}>
      <SwitchToggle />
    </SwitchButton>
    <SwitchLabel selected={toggled} onClick={onChange}>
      {offLabel}
    </SwitchLabel>
  </SwitchWrapper>
)
