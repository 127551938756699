// @flow

import * as React from 'react'

/**
 * The node ICU data are diferent on macOs and on Bitbucket Pipelines.
 * We normalize the macOs one to match the basic HTML non-breaking space char (@nbsp;)
 */
const normalizeNonBreakingSpace = str => str.replace(' ', ' ')

const Taxes = ({ amount }: {| amount: number |}) => {
  return (
    <React.Fragment>
      TVA{' '}
      {normalizeNonBreakingSpace(
        (amount / 100).toLocaleString('fr-FR', {
          style: 'percent',
          useGrouping: true,
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        }),
      )}
    </React.Fragment>
  )
}

export default Taxes
