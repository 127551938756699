// @flow

import React from 'react'
import styled from 'styled-components'
import { LabelInput } from '../Form'
import { Button } from '../Button'
import { Colors } from '../../Colors'
import { RobotoMedium, RobotoBold } from '../../Typography/Fonts'
import Price from '../../Typography/Price'

const Container = styled.div`
  width: 310px;
  margin-top: 20px;
`

const PriceInformations = styled.div`
  margin-top: 5px;
  padding: 0 16px;
  position: relative;
  height: 184px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: transparent;
  margin-bottom: 96px;
`

const Label = styled(LabelInput)`
  top: -6px;
  text-transform: uppercase;
`

const SubPrice = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 19px;
  justify-content: space-between;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 17px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 20px;
  color: ${Colors.blackUltraBold};
`

const TotalPrice = styled(SubPrice)`
  font-family: ${RobotoBold.fontFamily};
  font-size: 17px;
  font-weight: ${RobotoBold.fontWeight};
`

const WaitingPrice = styled.div`
  height: 280px;
  margin-top: 20px;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 17px;
  font-weight: ${RobotoMedium.fontWeight};
`

const Separator = styled.div`
  height: 1px;
  background-color: #dddddd;
`

export default ({
  lotPriceIncludingVAT,
  optionsPriceIncludingVAT,
  totalPriceIncludingVAT,
  openNewTabReceipt,
  receiptUrl,
}: {
  lotPriceIncludingVAT: ?number,
  optionsPriceIncludingVAT: ?number,
  totalPriceIncludingVAT: ?number,
  openNewTabReceipt: () => void,
  receiptUrl: ?string,
}) => {
  return (
    <Container>
      {typeof lotPriceIncludingVAT === 'number' &&
      typeof optionsPriceIncludingVAT === 'number' &&
      typeof totalPriceIncludingVAT === 'number' ? (
        <PriceInformations>
          <Label>Détail du prix</Label>
          <SubPrice>
            Options <Price amount={optionsPriceIncludingVAT} />
          </SubPrice>
          <Separator />
          <SubPrice>
            Logement <Price amount={lotPriceIncludingVAT} />
          </SubPrice>
          <Separator />
          <TotalPrice>
            Total <Price amount={totalPriceIncludingVAT} />
          </TotalPrice>
        </PriceInformations>
      ) : (
        <PriceInformations>
          <Label>Détail du prix</Label>
          <WaitingPrice>
            Les prix sont en cours de calcul, merci de patienter quelques
            instants.
          </WaitingPrice>
        </PriceInformations>
      )}
      <Button
        disabled={!receiptUrl}
        onClick={() => openNewTabReceipt()}
        value="Afficher le pdf"
      />
    </Container>
  )
}
