// @flow

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FloatingLabel, Form, SubmitButton, FieldError, Input } from '../Form'

type Customer = {
  customerId: string,
  firstName: string,
  lastName: ?string,
  email: string,
}

const Container = styled.div`
  width: 620px;
  margin-top: -10px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 10px;

  div {
    &:nth-of-type(1) {
      grid-column: 1 / 7;
    }
    &:nth-of-type(2) {
      grid-column: 1 / 3;
    }
    &:nth-of-type(3) {
      grid-column: 3 / 5;
    }
    &:nth-of-type(4) {
      grid-column: 5 / 7;
    }
    &:nth-of-type(5) {
      grid-column: 1 / 7;
    }
    &:nth-of-type(6) {
      grid-column: 1 / 4;
    }
    &:nth-of-type(7) {
      grid-column: 4 / 7;
    }
  }

  p {
    grid-column: 1 / 7;
  }
`

const ErrorClick = styled.span`
  cursor: pointer;
  text-decoration: underline;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 0.75;
  }
`

const getErrorByField = (
  errors: { source: string, message: string }[],
  source: string,
): ?{ source: string, message: string } => {
  return errors.find(oneError => oneError.source === source)
}

export default ({
  customers,
  onSubmit,
  errors,
  email,
  firstName,
  lastName,
  phone,
  address,
  zipCode,
  city,
  customerId,
  fetchLoadedCustomer,
  labelButton,
}: {
  customers: Customer[],
  labelButton: string,
  onSubmit: ({
    emailValue: string,
    firstNameValue: string,
    lastNameValue: string,
    phoneValue: string,
    addressValue: string,
    zipCodeValue: string,
    cityValue: string,
  }) => void,
  errors: { source: string, message: string }[],
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  address: string,
  zipCode: string,
  city: string,
  customerId: ?string,
  fetchLoadedCustomer: ?(customerId: string) => void,
}) => {
  const loadCustomer = (email: string) => {
    return customers.find(
      customer =>
        customer.email === email && customer.customerId !== customerId,
    )
  }

  const [emailValue, setEmail] = useState(email)
  const [firstNameValue, setFirstName] = useState(firstName)
  const [lastNameValue, setLastName] = useState(lastName)
  const [phoneValue, setPhone] = useState(phone)
  const [addressValue, setAddress] = useState(address)
  const [zipCodeValue, setZipCode] = useState(zipCode)
  const [cityValue, setCity] = useState(city)
  const [loadedCustomer, setLoadedCustomer] = useState(null)

  useEffect(() => {
    setEmail(email)
  }, [email])
  useEffect(() => {
    setFirstName(firstName)
  }, [firstName])
  useEffect(() => {
    setLastName(lastName)
  }, [lastName])
  useEffect(() => {
    setPhone(phone)
  }, [phone])
  useEffect(() => {
    setAddress(address)
  }, [address])
  useEffect(() => {
    setZipCode(zipCode)
  }, [zipCode])
  useEffect(() => {
    setCity(city)
  }, [city])

  const emailError = getErrorByField(errors, 'email')
  const firstNameError = getErrorByField(errors, 'firstName')
  const lastNameError = getErrorByField(errors, 'lastName')
  const phoneError = getErrorByField(errors, 'phone')
  const addressError = getErrorByField(errors, 'address')
  const zipCodeError = getErrorByField(errors, 'zipCode')
  const cityError = getErrorByField(errors, 'city')

  const disabled =
    emailValue === '' ||
    firstNameValue === '' ||
    lastNameValue === '' ||
    phoneValue === '' ||
    addressValue === '' ||
    zipCodeValue === '' ||
    cityValue === '' ||
    loadedCustomer

  return (
    <Container>
      <Form
        onSubmit={e => {
          e.preventDefault()
          onSubmit({
            emailValue,
            firstNameValue,
            lastNameValue,
            phoneValue,
            addressValue,
            zipCodeValue,
            cityValue,
          })
        }}
      >
        <Grid>
          <FloatingLabel label="Email">
            <Input
              type="text"
              value={emailValue}
              onChange={e => {
                setEmail(e.target.value)
                setLoadedCustomer(loadCustomer(e.target.value))
              }}
              placeholder="Email"
              errored={emailError || loadedCustomer}
            />
          </FloatingLabel>

          {emailError ? <FieldError>{emailError.message}</FieldError> : null}
          {loadedCustomer && fetchLoadedCustomer ? (
            <FieldError>
              Cet identifiant existe déjà.
              <ErrorClick
                onClick={() => {
                  fetchLoadedCustomer(loadedCustomer.customerId)
                  setLoadedCustomer(null)
                }}
              >
                Cliquez ici pour pré-remplir les informations du client.
              </ErrorClick>
            </FieldError>
          ) : null}

          <FloatingLabel label="Prénom">
            <Input
              type="text"
              value={firstNameValue}
              onChange={e => {
                setFirstName(e.target.value)
              }}
              placeholder="Prénom"
              errored={firstNameError}
            />
          </FloatingLabel>

          <FloatingLabel label="Nom">
            <Input
              type="text"
              value={lastNameValue}
              onChange={e => {
                setLastName(e.target.value)
              }}
              placeholder="Nom"
              errored={lastNameError}
            />
          </FloatingLabel>

          <FloatingLabel label="Téléphone">
            <Input
              type="text"
              value={phoneValue}
              onChange={e => {
                setPhone(e.target.value)
              }}
              placeholder="Téléphone"
              errored={phoneError}
            />
          </FloatingLabel>

          {firstNameError ? (
            <FieldError>{firstNameError.message}</FieldError>
          ) : null}
          {lastNameError ? (
            <FieldError>{lastNameError.message}</FieldError>
          ) : null}
          {phoneError ? <FieldError>{phoneError.message}</FieldError> : null}

          <FloatingLabel label="Adresse">
            <Input
              type="text"
              value={addressValue}
              onChange={e => {
                setAddress(e.target.value)
              }}
              placeholder="Adresse"
              errored={addressError}
            />
          </FloatingLabel>

          {addressError ? (
            <FieldError>{addressError.message}</FieldError>
          ) : null}

          <FloatingLabel label="Code Postal">
            <Input
              type="text"
              value={zipCodeValue}
              onChange={e => {
                setZipCode(e.target.value)
              }}
              placeholder="Code Postal"
              errored={zipCodeError}
            />
          </FloatingLabel>

          <FloatingLabel label="Ville">
            <Input
              type="text"
              value={cityValue}
              onChange={e => {
                setCity(e.target.value)
              }}
              placeholder="Ville"
              errored={cityError}
            />
          </FloatingLabel>

          {zipCodeError ? (
            <FieldError>{zipCodeError.message}</FieldError>
          ) : null}
          {cityError ? <FieldError>{cityError.message}</FieldError> : null}
        </Grid>

        <SubmitButton disabled={disabled} value={labelButton} />
      </Form>
    </Container>
  )
}
