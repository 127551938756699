// @flow

import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import LotPricesBulkEditionInput from './LotPricesBulkEditionInput'
import { SubmitButton } from '../../Guidelines/components/Form'
import Lot from './Lot'
import { Colors } from '../../Guidelines'

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  ${SubmitButton} {
    height: min-content;
    margin-top: 30px;
  }
`

const ErrorMessage = styled.p`
  margin: 30px 0 0 0;
  color: ${Colors.pinkBold};
  text-align: center;
`

type Props = {
  lots: Lot[],
  selectedBuildingId: string,
  onLotPriceIncludingTaxes55Changed: (
    lotId: string,
    newLotPriceIncludingTaxes55: number,
  ) => void,
  onLotPriceIncludingTaxes20Changed: (
    lotId: string,
    newLotPriceIncludingTaxes20: number,
  ) => void,
  onSubmit: () => Promise<void>,
}
const confirmOnPageExit = e => {
  e = e || window.event
  var message =
    "🤔 Vous êtes sur le point de quitter la page d'édition. Les changements de prix non sauvegardés seront définitivement perdus, merci de confirmer votre choix."
  if (e) {
    e.returnValue = message
  }
  return message
}
const LotPricesBulkEditionForm = ({
  lots,
  selectedBuildingId,
  onLotPriceIncludingTaxes20Changed,
  onLotPriceIncludingTaxes55Changed,
  onSubmit,
}: Props) => {
  const [isBeingSubmitted, setIsBeingSubmitted] = React.useState(() => false)
  const [submissionError, setSubmissionError] = React.useState(() => null)

  const pricesChanged = lots.some(oneLot => oneLot.isPricesChanged)
  const priceCouldBeSubmitted =
    pricesChanged && lots.every(oneLot => oneLot.haveValidNewPrice)

  window.onbeforeunload = pricesChanged && confirmOnPageExit
  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        setIsBeingSubmitted(true)

        Promise.resolve(onSubmit())
          .catch(() => {
            setSubmissionError(
              'Une erreur est survenu lors de la sauvegarde des prix.',
            )
          })
          .finally(() => {
            setIsBeingSubmitted(false)
          })
      }}
      style={{
        opacity: isBeingSubmitted ? 0.5 : 1,
        pointerEvents: isBeingSubmitted ? 'none' : 'initial',
        transition: 'opacity 250ms ease-in-out',
      }}
    >
      <LotPricesBulkEditionInput
        lots={lots}
        selectedBuildingId={selectedBuildingId}
        onLotPriceIncludingTaxes20Changed={onLotPriceIncludingTaxes20Changed}
        onLotPriceIncludingTaxes55Changed={onLotPriceIncludingTaxes55Changed}
      />

      {submissionError ? <ErrorMessage>{submissionError}</ErrorMessage> : null}

      <SubmitButton
        value="Valider"
        disabled={!priceCouldBeSubmitted || isBeingSubmitted}
      />
    </Form>
  )
}

export default observer(LotPricesBulkEditionForm)
