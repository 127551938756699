// @flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import CrossSvg from '../icons/CrossSvg'
import { Colors } from '../Colors'
import { RobotoBold, RobotoMedium } from '../Typography/Fonts'

const Backdrop = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  overflow: auto;
`

const ModalContainer = styled.div`
  position: relative;
  width: min-content;
  border-radius: 8px;
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.25);
  background-color: ${Colors.white};
  margin: auto;
  padding: 20px 30px 30px 30px;
`

const ModalContent = styled.div`
  width: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const ModalTitle = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 34px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 1.18;
  color: ${Colors.blackUltraBold};
  text-shadow: 0 1px 0 ${Colors.white};
  margin-right: 10px;
  text-decoration: none;
  &:hover {
    color: ${({ href }) => (href ? Colors.green : Colors.blackUltraBold)};
    text-decoration: ${({ href }) =>
      href ? `underline ${Colors.green}` : 'none'};
  }
`

const ModalSubTitle = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 13px;
  font-weight: ${RobotoMedium.fontWeight};
  color: ${Colors.greyRegular};
  width: min-content;
`

const CloseButton = styled.button`
  position: absolute;
  top: 28px;
  right: 30px;
  padding: 0px;
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
`

const StepsContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
`

const Step = styled.div`
  border-radius: 3px;
  width: 6px;
  height: 6px;
  background-color: ${({ active }) =>
    active ? Colors.green : Colors.greyLight};
  margin: 0 2px;
`

type ModalProps = {
  title: ?string,
  onClose: () => void,
  children: React.Node,
  subTitle: ?string,
  steps?: string[],
  stepActive?: string,
  linkTo?: string,
}

export default class DialogModal extends React.PureComponent<ModalProps> {
  _container: HTMLDivElement
  _wrapperRef: any

  constructor() {
    super()

    this._container = window.document.createElement('div')
    this._wrapperRef = React.createRef()
    window.document.body.appendChild(this._container)
  }

  componentDidMount() {
    window.document.addEventListener('mousedown', this.handleClickOutside)
    window.document.addEventListener('keydown', this.handleEscapeKey)
  }

  componentWillUnmount() {
    window.document.body.removeChild(this._container)
    window.document.removeEventListener('mousedown', this.handleClickOutside)
  }

  onClose = () => {
    this.props.onClose()
  }

  handleEscapeKey = (event: SyntheticKeyboardEvent<*>) => {
    if (event.key === 'Escape') {
      this.onClose()
    }
  }

  handleClickOutside = (event: SyntheticEvent<HTMLElement>) => {
    if (this._wrapperRef && !this._wrapperRef.contains(event.target)) {
      this.onClose()
    }
  }

  render() {
    const { steps, stepActive, linkTo } = this.props
    return ReactDOM.createPortal(
      <Backdrop>
        <ModalContainer ref={node => (this._wrapperRef = node)}>
          <CloseButton onClick={() => this.onClose()}>
            <CrossSvg />
          </CloseButton>
          <ModalContent>
            <TitleContainer>
              <ModalTitle
                as={linkTo ? 'a' : 'div'}
                target="_blank"
                href={linkTo}
              >
                {this.props.title}
              </ModalTitle>
              <ModalSubTitle>{this.props.subTitle}</ModalSubTitle>
            </TitleContainer>
            {this.props.children}
          </ModalContent>
          {steps ? (
            <StepsContainer>
              {steps.map(step => (
                <Step key={step} active={step === stepActive} />
              ))}
            </StepsContainer>
          ) : null}
        </ModalContainer>
      </Backdrop>,
      this._container,
    )
  }
}
